import React, { FunctionComponent, useEffect, useRef, useState } from "react"
import * as THREE from "three"
import { FirstPersonControls } from "../../Controls/FirstPersonControls/FirstPersonControls"
import {
  iReduction,
  iExhibition,
  iQualityReduction,
  iTransform,
  iSettings,
  iPortal,
} from "../../definitions"
import { Group, LinearToneMapping, Mesh, sRGBEncoding } from "three"
import Stats from "../../Helpers/Stats/Stats"
import SceneLoader from "../../Loaders/SceneLoader/SceneLoader"
import { ReactComponent as Close } from "../../Components/Icons/close.svg"
import { useHistory, useRouteMatch } from "react-router-dom"
import { getMesh } from "../../Helpers/requests"
import { parseQuery } from "../../Helpers/functions"
import { unstable_batchedUpdates } from "react-dom"
import { useScene } from "../../hooks/useScene"
import { SceneLoaderUtilities } from "../../Loaders/SceneLoader/SceneLoaderUtilities"
import { isMobile } from "react-device-detect"
import Menu from "../../Components/Overlay/Menu"
import ExhibitionLoader from "../../Components/Loaders/ExhibitionLoader"
import { API_URL } from "../../api/Definitions"
import { VideoPlayer } from "../../Components/VideoPlayer/VideoPlayer"
import { group } from "console"

const Exhibition: FunctionComponent = () => {
  const [isLoading, setLoading] = useState(true)
  const [isAddLoading, setAddLoading] = useState(false)
  const [hasDoubleTapped, setHasDoubleTapped] = useState(false)
  const [isVideoHovered, setVideoHovered] = useState(false)
  const [videoPlayer, setVideoPlayer] = useState<VideoPlayer>()
  const [isPlaying, setPlaying] = useState(false)

  const [showIframe, setShowIframe] = useState(false)
  const [iframeSource, setIframe] = useState("")
  const [progress, setProgress] = useState(0)
  const [exhibition, setExhibition] = useState<iExhibition>()
  const [audioGuideURL, setAudioGuide] = useState("")
  const container = useRef<HTMLDivElement>(null)
  const match = useRouteMatch<{ url: string }>()
  const history = useHistory()
  const outlineThickness = 1.015
  const playerHeight = 1.65
  let previousSceneHDR: THREE.CubeTexture

  //camera
  const fieldOfView = 60
  const near = 0.1
  const far = 10000

  //tags
  const artPieceTag = "ArtPiece"
  const outlineTag = "outline"
  const videoTag = "Video"
  const portalTag = "Portal"
  const infoTag = "InfoPoint"

  //roots
  const vrAllArtRoot = "https://content.vrallart.com"

  //resolutions
  const mobileResolution = 1024
  const pcResolution = 2048

  const { renderer, scene } = useScene({ container })

  let outline: THREE.Object3D

  useEffect(() => {
    const container = document.getElementById("stats")
    window.addEventListener("resize", onWindowResize)
    const stats = Stats()
    container?.appendChild(stats.dom)
    scene.background = new THREE.Color(1, 1, 1)
    const camera = new THREE.PerspectiveCamera(
      fieldOfView,
      window.innerWidth / window.innerHeight,
      near,
      far,
    )

    let portal: iPortal
    let prevPortal: iPortal

    camera.layers.enable(0)
    camera.layers.disable(1)
    camera.layers.disable(2)
    camera.layers.disable(3)

    const scenes: Group[] = []

    if (!isMobile) {
      renderer.setPixelRatio(window.devicePixelRatio)
    } else {
      renderer.setPixelRatio(window.devicePixelRatio / 2)
    }
    renderer.outputEncoding = sRGBEncoding
    renderer.toneMapping = LinearToneMapping
    renderer.toneMappingExposure = 1
    renderer.setSize(window.innerWidth, window.innerHeight - 10)

    const sub_scene_group = new THREE.Group()
    sub_scene_group.scale.set(1, 1, -1)
    scene.add(sub_scene_group)

    const main_scene_group = new THREE.Group()
    main_scene_group.scale.set(1, 1, -1)
    scene.add(main_scene_group)

    scenes.push(main_scene_group)
    scenes.push(sub_scene_group)

    const query = parseQuery(history.location.search)

    const controls = new FirstPersonControls(
      camera,
      renderer.domElement,
      playerHeight,
      scene,
    )

    loadSpace(match.params.url, query.draft, main_scene_group)

    function getSpawnPosition(sp: iSettings) {
      if (portal) {
        const trans: iTransform = {} as iTransform
        if (portal.exit) {
          trans.position = portal?.exit?.position
          trans.rotation = portal?.exit?.quaternion
          return trans
        }
      } else {
        let spawnPoint = sp.spawnPointWeb as iTransform
        // SpawnPoint
        const pos = new THREE.Vector3(
          spawnPoint.position.x,
          spawnPoint.position.y,
          spawnPoint.position.z,
        )
        console.log(sp)

        if (pos.equals(new THREE.Vector3(0, 0, 0))) {
          spawnPoint = sp.spawnPointVR as iTransform
        }
        return spawnPoint
      }
    }

    function toggleScenePortals(scene: Group, enable: boolean) {
      scene.traverse((child) => {
        if (child.userData.tag === portalTag) {
          if (enable) {
            child.layers.set(2)
          } else {
            child.layers.set(4)
          }
        }
      })
    }

    function loadSpace(
      url: string,
      draft: boolean,
      group: THREE.Group,
      fromscene = "",
    ) {
      getMesh({
        link: `${API_URL}/v1/${draft ? "draft" : "public"}-exhibitions/${url}`,
        query,
      }).then(({ json, doc }) => {
        console.log(
          `${API_URL}/v1/${draft ? "draft" : "public"}-exhibitions/${url}`,
        )

        const exhibition = doc as iExhibition
        console.log(exhibition)

        group.userData.sceneName = url
        currentScene = group
        setExhibition(exhibition)
        if(exhibition.settings !== undefined) {
          const sp = JSON.parse(exhibition.settings) as iSettings
          exhibition.parsedSettings = sp
          console.log(json)
          SceneLoaderUtilities.SetPortals(group, sp)
          const spawnPoint = getSpawnPosition(sp)
          if (spawnPoint) {
            controls.setPosition(
              spawnPoint.position,
              spawnPoint.rotation as THREE.Quaternion,
              )
            }
          }
          
          // controls.setPosition(
          //   new THREE.Vector3(0, 1.8, 1),
          //   new THREE.Quaternion(0, 0, 0, 1)
          // )
        if (exhibition.descriptionAudio) {
          setAudioGuide(
            `https://content.vrallart.com/${exhibition.descriptionAudio.original.meta.pipePath}`,
          )
        }


        const sceneLoader = new SceneLoader({
          json,
          renderer: renderer,
          group: group,
          root: vrAllArtRoot,
          reduction: new iQualityReduction(iReduction.x16, 128),
          resetPosition: false,
          onProgress: (val) => {
            setProgress(val)
            if (val === 1) {
              unstable_batchedUpdates(() => {
                setLoading(false)
                setAddLoading(true)
                setProgress(0)
              })
              sceneLoader.OnProgress = (val) => {
                setProgress(val)
                if (val === 1) {
                  setAddLoading(false)
                  subscribeToControllerEvents(controls)
                  onWindowResize()
                }
              }
              sceneLoader.LoadPass(
                isMobile
                  ? new iQualityReduction(iReduction.x4, mobileResolution)
                  : new iQualityReduction(iReduction.x2, pcResolution),
                true,
              )
            }
          },
        })

        sceneLoader.Load().then(() => {
          sceneLoader.ReorderObjects()
          SceneLoaderUtilities.UpdateArtifacts(exhibition, scene)
        })
      })
    }

    let currentScene = scenes[0]

    //function that will go through all the objects in the scene and check if they are interactable
    function toggleInteractableObjects(scene: THREE.Group, active: boolean): void {
      scene.traverse((object: THREE.Object3D) => {
        
        object.userData.interactable = active;
    });
}



    function controllerInteractEvent(event: any) {
      const interact = event.message
      let url = ""
      if (interact.externalUrl !== undefined && interact.externalUrl !== "") {
        url = interact.externalUrl
      } else if (
        interact.destination !== undefined &&
        interact.destination !== ""
      ) {
        url = interact.destination
        prevPortal = portal
        portal = interact as iPortal
        const newScene = findNotCurrentScene(portal.destination)
        if (currentScene.userData.sceneName === portal.destination) {
          return
        }
        stopAllVideoPlayers(scene)
        if (newScene) {
          const prevSceneName = currentScene.userData.sceneName
          currentScene.visible = false
          toggleInteractableObjects(currentScene,false)
          toggleScenePortals(currentScene, false)
          currentScene = newScene
          const currentSceneHDR = scene.background as THREE.CubeTexture
          scene.background = previousSceneHDR
          previousSceneHDR = currentSceneHDR
          toggleScenePortals(currentScene, true)
          toggleInteractableObjects(currentScene,true)
          currentScene.visible = true
          const port = findScenePortal(currentScene, prevSceneName)
          if (portal) {
            controls.setPosition(portal.exit.position, portal.exit.quaternion)
          }
          return
        } else {
          //find scene that is not current scene
          const sceneToLoad = scenes.find((s) => s !== currentScene)
          if (sceneToLoad) {
            clearGroup(sceneToLoad)
            setLoading(true)
            setProgress(0)
            previousSceneHDR = scene.background as THREE.CubeTexture
            const prevSceneName = currentScene.userData.sceneName
            currentScene.visible = false
            toggleScenePortals(currentScene, false)
            toggleInteractableObjects(currentScene,false)
            currentScene = sceneToLoad
            toggleInteractableObjects(currentScene,true)
            sceneToLoad.userData.sceneName = portal.destination
            loadSpace(url, true, sceneToLoad, prevSceneName)
            currentScene.visible = true
            const port = findScenePortal(currentScene, prevSceneName)
            if (portal) {
              controls.setPosition(portal.exit.position, portal.exit.quaternion)
            }
            return
          }
        }
      }
      else if(interact.info !== undefined) {
          const info = interact.info
          const eventData = {
            id: info.title,
            type: info.description,
          }

          window.parent.postMessage(eventData, "*")
          window.postMessage(eventData, "*")
          return;
      }
      else {
        url = interact.url
      }
      
      if(interact.event !== undefined) {        
        if(interact.event.eventType !== "") {
          const eventData = {
            id: interact.event.id,
            type: interact.event.eventType,
          }
  
          window.parent.postMessage(eventData, "*")
          window.postMessage(eventData, "*")
          return;
        }
      }
      
      if (url === "" || url.includes("undefined") === true) {
        return
      }

      unstable_batchedUpdates(() => {
        // setIframe(url)
        // setShowIframe(true)
        window.open(url, "_blank")
      })
    }

    function findScenePortal(scene: Group, name: string): Mesh | undefined {
      const portals = scene.children.filter(
        (c) => c.userData.tag === "Portal" && c.userData.destination === name,
      )
      if (portals.length > 0) {
        return portals[0] as Mesh
      }
      return undefined
    }

    function findNotCurrentScene(name: string): Group | undefined {
      for (let i = 0; i < scenes.length; i++) {
        if (scenes[i].userData.sceneName === name) {
          return scenes[i]
        }
      }

      return undefined
    }

    function clearGroup(group: THREE.Group) {
      while (group.children.length > 0) {
        group.remove(group.children[0])
      }
    }

    //#region create player controller
    function subscribeToControllerEvents(controls: any) {
      //controller events
      //add listener on controller interaction
      controls.addEventListener("interact", controllerInteractEvent)

      //add listener on controller hover enter for interactable objects
      controls.addEventListener("onInteractableHover", function (event: any) {
        if (isMobile) return
        
        checkIntersection(event.message)
      })

      controls.addEventListener(
        "onInteractableHoverVideo",
        function (event: any) {
          setVideoHovered(true)          
          if (event.message.userData.colTarget === videoPlayer) return
          setVideoPlayer(event.message.userData.colTarget)
          setPlaying(event.message.userData.colTarget?.isPlaying)
        },
      )

      controls.addEventListener(
        "onInteractableHoverEndVideo",
        function (event: any) {
          // setVideoPlayer(undefined)
          setVideoHovered(false)
        },
      )

      controls.addEventListener("onDoubleTapped", function (event: any) {
        setHasDoubleTapped(true)
      })

      //add listener on controller hover exit for interactable objects
      controls.addEventListener("onInteractableHoverEnd", function () {
        if (isMobile) return
        if (outline) {
          outline.visible = false
        }
        outline?.remove()
        outline = new THREE.Object3D()
        checkIntersection(new THREE.Object3D())
      })
    }

    controls.init(
      main_scene_group.position.x,
      main_scene_group.position.y,
      main_scene_group.position.z,
    )

    function checkIntersection(selectedObject: THREE.Object3D) {
      addSelectedObject(selectedObject)
    }

    function getVideoPlayer(scene: THREE.Scene, name: string): VideoPlayer {
      const videoPlayer = scene.getObjectByName(name) as VideoPlayer
      return videoPlayer
    }

    function getAllVideoPlayers(scene: THREE.Scene): VideoPlayer[] {
      const videoPlayers: VideoPlayer[] = []
      scene.traverse((child) => {
        if (child instanceof VideoPlayer) {
          videoPlayers.push(child)
        }
      })
      return videoPlayers
    }

    function stopAllVideoPlayers(scene: THREE.Scene) {
      const videoPlayers = getAllVideoPlayers(scene)
      videoPlayers.forEach((vp) => {
        vp.pause()
      })
    }

    function addSelectedObject(object: THREE.Object3D) {
      if (object === undefined || outline === undefined) return
      if (
        object.userData.tag === artPieceTag ||
        object.userData.tag === portalTag ||
        object.userData.tag === infoTag
      ) {
        if (outline.userData.tag === outlineTag) {
          return
        }
        const tag = object.userData.typetag

        if (object.userData.colTarget !== undefined) {
          object.renderOrder = 0
          object = object.userData.colTarget
        }

        if (object instanceof VideoPlayer) {
          object = object.videoMesh
        } else {
          if (object.userData === undefined) return

          if (
            object.userData.typetag === "VideoControlls" ||
            object.userData.typetag === videoTag
          ) {
            if (object.userData.videoTarget !== undefined) {
              object = object.userData.videoTarget.videoMesh
            } else {
              const vf = getVideoPlayer(scene, object.userData.file)
              if (vf) {
                object = vf.videoMesh
              }
            }
          }
        }

        if (object === undefined) {
          return
        }

        if(object.userData.tag === infoTag) {
          const objName = object.name.replace("-collider-", "")
          if(object.userData.infoTarget !== undefined) {
            object.attach(object.userData.infoTarget)
          }
          else{
            return;
          }          
        }

        if (object.userData !== undefined) {
          if (object.userData.outline) {
            outline = object.userData.outline
            outline.visible = true
            return
          }
        }        

        SceneLoaderUtilities.CreateObjectOutline(
          object,
          currentScene,
          outlineThickness,
          tag === "VideoControlls" || tag === videoTag || tag === portalTag,// || tag === infoTag,
        )
      } else {
        outline.visible = false
        outline = new THREE.Object3D()
      }
    }

    function onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight
      camera.updateProjectionMatrix()
      renderer?.setSize(window.innerWidth, window.innerHeight)
    }

    function animate() {
      requestAnimationFrame(animate)
      controls.update()
      renderer?.render(scene as THREE.Scene, camera)
    }
    animate()
  }, [])

  const onInfoClose = () => {
    unstable_batchedUpdates(() => {
      setIframe("")
      setShowIframe(false)
    })
  }

  const onPlayClick = () => {
    if (videoPlayer) {
      setPlaying(!videoPlayer?.isPlaying)
      videoPlayer.toggleState()
    }
  }

  return (
    <div ref={container} className="App">
      {showIframe && (
        <div>
          <div onClick={onInfoClose} className="close">
            <Close />
          </div>
          <iframe src={iframeSource} frameBorder="0"></iframe>
        </div>
      )}
      {isLoading && (
        <ExhibitionLoader exhibition={exhibition} progress={progress} />
      )}
      {isAddLoading && (
        <div className="additionalLoader">
          <div className="additionalLoaderWrap">
            <h1>HIGH QUALITY LOADING</h1>
            <div className="innerLoaderHolder">
              <div
                className="innerLoader"
                style={{ width: progress * 100 + "%" }}
              ></div>
            </div>
          </div>
        </div>
      )}
      {isLoading === false && (
        <>
          <div className="screenOverlay">
            <img
              className="logo"
              src={window.location.origin + "/icons/all.art_logo_dafa.png"}
            />
            {isAddLoading === false && (
              <>
                {!isMobile ? (
                  <div className="bottomPannel">
                    <div className="bottomKeys">
                      <img
                        src={window.location.origin + "/icons/vraa_buttons.png"}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    {hasDoubleTapped === false && (
                      <div className="bottomPannel">
                        <div className="additionalLoader">
                          <div className="additionalLoaderWrap">
                            <h1>DOUBLE TAP TO MOVE AND INTERACT</h1>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
            {isVideoHovered && isMobile &&(
              <div className="videoHover">
                <div className="videoHoverInner">
                  <button className="play-button" onClick={onPlayClick}>
                    {isPlaying ? (
                      <img
                        src={window.location.origin + "/icons/pause_new.png"}
                      />
                    ) : (
                      <img
                        src={window.location.origin + "/icons/play_new.png"}
                      />
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>
          <Menu
            audioSrc={audioGuideURL}
            title={exhibition?.title}
            subtitle={exhibition?.subtitle}
            object={container.current}
          />
        </>
      )}
    </div>
  )
}

export default Exhibition
