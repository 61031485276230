import React, { FunctionComponent } from "react"
import { iExhibition } from "../../definitions"
import { eImageVersions } from "../../definitions/Image"
import { useImageUrl } from "../../hooks/useImageUrl"
import "./ExhibitionLoader.css"

interface iProps {
  exhibition?: iExhibition
  progress: number
}

const ExhibitionLoader: FunctionComponent<iProps> = ({
  exhibition,
  progress,
}) => {
  const imageUrl = useImageUrl(
    eImageVersions.LWRegular,
    exhibition?.screenshots && exhibition?.screenshots[0],
  )

  const blur = (10 - progress * 10).toFixed(2)

  return (
    <div className="exhibition-loader">
      <div
        className="background"
        style={{
          backgroundImage: `url(${imageUrl})`,
          filter: `blur(${blur}px)`,
        }}
      ></div>
      <div className="loader-wrapper">
        <div className="exhibition-logo">
          <img src="/icons/all.art_logo_dafa.png" alt="" />
        </div>
        <div className="bar">
          <div
            style={{ width: (progress * 100).toFixed(0) + "%" }}
            className="progress"
          ></div>
        </div>
      </div>
    </div>
  )
}

export default ExhibitionLoader
