import React, { FunctionComponent } from "react"

interface iProps {
  progress: number
}

const Loader: FunctionComponent<iProps> = ({ progress }) => {
  return (
    <div className="loader">
      <h1 className="loading">{(progress * 100).toFixed(0)}</h1>
    </div>
  )
}

export default Loader
