import * as THREE from "three"
import {
  Color,
  Object3D,
  PerspectiveCamera,
  Quaternion,
  Vector2,
  Vector3,
} from "three"
import { VideoPlayer } from "../../Components/VideoPlayer/VideoPlayer"

import { PointerRaycast } from "./PointerRaycat"
import { isMobile } from "react-device-detect"
import { log } from "console"

enum EPointerState {
  NONE,
  DOWN,
  DRAG,
  UP,
}

export class FirstPersonControls extends THREE.Object3D {
  scene: THREE.Scene
  camera: THREE.Camera
  height = 0
  raycaster = new THREE.Raycaster()
  pointerRaycaster: PointerRaycast
  canvas: HTMLCanvasElement
  moveSpeed = 3.0
  acceleration = 7.0

  circleSize = 0.005
  private inertiaRotModifier = 0.8

  cameraSensitivity = isMobile ? 0.2 : 0.06
  startMouse: THREE.Vector2 = new THREE.Vector2(0, 0)
  lastMousePosition: THREE.Vector2 = new THREE.Vector2(0, 0)
  mouseDirection: Vector2 = new THREE.Vector2()
  mouseDirectionNormalized: Vector2 = new THREE.Vector2()

  private circle: THREE.Mesh

  yDamping = 0.1
  stepHeight = 0.8

  private moveForward = false
  private moveBackward = false
  private moveLeft = false
  private moveRight = false
  private speed = 0
  private direction = new THREE.Vector3()
  private mouse = new THREE.Vector2(0, 0)
  private downMousePosition = new THREE.Vector2(0, 0)
  private moveToPosition = new THREE.Vector3()
  private cameraTargetPosition = new THREE.Vector3()
  private previousPosition = new THREE.Vector3()
  private clock = new THREE.Clock()
  private rotSpeed = 0
  private startInertia = 0
  private xClickOffset = 16
  private yClickOffset = 24
  private maxTapLength = 500
  private minDistanceToMove = 0.05
  private walkableYAxisPlayerOffset = 0.01

  private pointerState = EPointerState.NONE
  private pointerDownTime = 0

  private updateTime = 0
  private updateTimeDelta = 0.016
  private dragTimeThreshold = 1
  private lastPlayer: any = undefined
  scope = this

  //collision
  private yCollisionAngle = 0.785398
  private collisionIntersectionDistance = 0.2
  private raycastFar = 100

  //cursor
  private minDistanceToDragCursor = 0.01

  //touch event params
  private timeout: any
  private lastTap = 0

  //tags
  private videoTag = "Video"
  private artPieceTag = "ArtPiece"
  private walkableTag = "Walkable"
  private videoControllsTag = "VideoControlls"

  //cursor
  private walkableCursor = "walkable-cursor"
  private searchCursor = "search-cursor"
  private handCursor = "hand-cursor"
  private moveCursor = "Move_Cursor"

  constructor(
    cameraNew: THREE.Camera,
    domElement: HTMLCanvasElement,
    characterHeight: number,
    parentScene: THREE.Scene,
  ) {
    super()
    if (domElement === undefined) {
      console.warn(
        'THREE.FirstPersonControls: The second parameter "domElement" is now mandatory.',
      )
    }

    this.height = characterHeight
    this.scene = parentScene
    this.camera = cameraNew

    this.height = characterHeight
    this.canvas = domElement

    this.pointerRaycaster = new PointerRaycast(
      this.camera,
      1,
      this.scene,
      this.canvas,
    )
    this.circle = new Object3D() as THREE.Mesh

    new THREE.TextureLoader().load(
      window.location.origin + "/GridTexture.png",
      (t) => {
        const geometry = new THREE.PlaneGeometry(0.4, 0.4, 32)
        const material = new THREE.MeshPhysicalMaterial({ color: 0xffffff })

        material.transparent = true
        t.wrapS = THREE.RepeatWrapping
        t.wrapT = THREE.RepeatWrapping
        t.encoding = THREE.sRGBEncoding
        t.minFilter = THREE.LinearFilter
        t.magFilter = THREE.LinearFilter
        material.emissive = new Color(0x7effed).convertGammaToLinear()
        material.map = t
        material.depthWrite = false

        const mesh = new THREE.Mesh(geometry, material)
        mesh.renderOrder = 2
        this.circle.add(mesh)
        mesh.position.set(0, 0, 0)
        this.circle.rotateOnWorldAxis(new Vector3(1, 0, 0), -1.5708)
        mesh.userData = { tag: this.walkableTag, name: this.moveCursor }
        this.scene.add(this.circle)
      },
      undefined,
      (e) => {
        console.log(e)
      },
    )
  }

  init(x: number, y: number, z: number) {
    this.camera.position.set(x, y, z)

    this.moveToPosition = new THREE.Vector3(0, 0, 0)

    if (!isMobile) {
      window.addEventListener("mousedown", this.onPointerDown, false)
      window.addEventListener("mouseup", this.onPointerUp, false)

      window.addEventListener("mousemove", this.onPointerMove, false)
      window.addEventListener("keydown", this.onKeyDown, false)
      window.addEventListener("keyup", this.onKeyUp, false)
    } else {
      this.canvas.addEventListener("touchstart", this.onTouchDown, false)
      this.canvas.addEventListener("touchend", this.onTouchUp, false)
      this.canvas.addEventListener("touchmove", this.onTouchMove, false)
    }

    this.raycaster = new THREE.Raycaster(
      new THREE.Vector3(),
      new THREE.Vector3(0, -1, 0),
      0,
      0.4,
    )
  }

  onPointerMove = (event: MouseEvent) => {
    this.mouse = new THREE.Vector2(0, 0)
    this.mouse.x =
      ((event.clientX + this.xClickOffset) / window.innerWidth) * 2 - 1
    this.mouse.y =
      -((event.clientY + this.yClickOffset) / window.innerHeight) * 2 + 1

    const mousePos = new Vector2(event.screenX, event.screenY)
    if (this.pointerState == EPointerState.DRAG) {
      this.mouseDirection = new Vector2(0, 0)
      this.mouseDirection.subVectors(mousePos, this.lastMousePosition)
      this.mouseDirectionNormalized.set(
        this.mouseDirection.x,
        this.mouseDirection.y,
      )
      this.mouseDirectionNormalized.normalize()
      this.rotateCamera()
    }
    this.lastMousePosition = mousePos
  }

  setPosition(pos: Vector3, rot: Quaternion, flipZ = true) {
    this.camera.position.set(pos.x, pos.y, -pos.z)
    this.moveToPosition = new Vector3(pos.x, pos.y, -pos.z)
    const q = new THREE.Quaternion(-rot.x, rot.y, rot.z, -rot.w)

    const v = new THREE.Euler()
    v.setFromQuaternion(q)
    // v.y += Math.PI // Y is 180 degrees off
    if(flipZ)
    {
      v.z *= -1 // flip Z
    }
    this.camera.rotation.copy(v)
  }

  onTouchMove = (event: TouchEvent) => {
    event.preventDefault() // prevent scrolling
    event.stopPropagation()

    this.mouse = new THREE.Vector2(0, 0)
    this.mouse.x = (event.touches[0].clientX / window.innerWidth) * 2 - 1
    this.mouse.y = -(event.touches[0].clientY / window.innerHeight) * 2 + 1
    const mousePos = new Vector2(
      event.changedTouches[0].screenX / 2,
      event.changedTouches[0].screenY / 2,
    )

    this.pointerState = EPointerState.DRAG

    if (
      this.pointerState === EPointerState.DRAG &&
      mousePos !== new Vector2()
    ) {
      this.mouseDirection = new Vector2(0, 0)
      this.mouseDirection.subVectors(mousePos, this.lastMousePosition)
      this.mouseDirectionNormalized.set(
        this.mouseDirection.x,
        this.mouseDirection.y,
      )
      this.mouseDirectionNormalized.normalize()
      this.rotateCamera()
    }
    this.lastMousePosition = mousePos
  }

  rotateCamera() {
    this.camera.rotation.order = "YXZ"
    if (
      this.pointerState == EPointerState.DRAG &&
      this.mouseDirection.length() > 0.2
    ) {
      this.rotSpeed = this.mouseDirection.length()
      this.startInertia = this.rotSpeed
      const movementY =
        (this.mouseDirectionNormalized.y *
          this.rotSpeed *
          Math.PI *
          this.cameraSensitivity) /
        180

      const movementX =
        (this.mouseDirectionNormalized.x *
          this.rotSpeed *
          Math.PI *
          this.cameraSensitivity) /
        180

      this.camera.rotateOnWorldAxis(
        new THREE.Vector3(0, 1, 0),
        THREE.MathUtils.degToRad(50 * movementX),
      )
      let xRot = this.camera.rotation.x
      xRot += movementY
      this.camera.rotation.x = THREE.MathUtils.clamp(
        xRot,
        -Math.PI * 0.5,
        Math.PI * 0.5,
      )

      this.startInertia = 1
    } else {
      this.startInertia -= this.updateTimeDelta * this.inertiaRotModifier
      if (this.startInertia > 0 && this.rotSpeed > 1) {
        const movementY =
          (this.mouseDirectionNormalized.y *
            Math.PI *
            this.cameraSensitivity *
            this.rotSpeed *
            this.startInertia) /
          180

        const movementX =
          (this.mouseDirectionNormalized.x *
            Math.PI *
            this.cameraSensitivity *
            this.rotSpeed *
            this.startInertia) /
          180

        this.camera.rotateOnWorldAxis(
          new THREE.Vector3(0, 1, 0),
          THREE.MathUtils.degToRad(50 * movementX),
        )

        let xRot = this.camera.rotation.x
        xRot += movementY
        this.camera.rotation.x = THREE.MathUtils.clamp(
          xRot,
          -Math.PI * 0.5,
          Math.PI * 0.5,
        )
      } else {
        this.rotSpeed = 0
      }
    }
  }

  onPointerDown = () => {
    this.pointerState = EPointerState.DOWN
    this.pointerDownTime = this.updateTime
    this.downMousePosition = this.mouse
  }

  onPointerUp = () => {
    if (
      this.pointerState != EPointerState.DRAG &&
      this.updateTime - this.pointerDownTime < this.dragTimeThreshold
    ) {
      if (!this.traceInteractable()) {
        this.traceMoveToPoint()
      }
    }
    this.pointerState = EPointerState.UP
  }

  onTouchDown = (event: TouchEvent) => {
    const mousePos = new Vector2(
      event.changedTouches[0].screenX / 2,
      event.changedTouches[0].screenY / 2,
    )

    this.pointerState = EPointerState.DOWN
    this.pointerDownTime = this.updateTime
    this.downMousePosition = this.mouse
    this.lastMousePosition = mousePos
  }

  onTouchUp = (event: TouchEvent) => {
    const currentTime = new Date().getTime()
    const tapLength = currentTime - this.lastTap

    if (this.pointerState === EPointerState.DRAG) {
      this.pointerState = EPointerState.UP
    } else {
      if (tapLength < this.maxTapLength && tapLength > 0) {
        this.mouse = new THREE.Vector2(0, 0)
        this.mouse.x =
          (event.changedTouches[0].clientX / window.innerWidth) * 2 - 1
        this.mouse.y =
          -(event.changedTouches[0].clientY / window.innerHeight) * 2 + 1
        this.downMousePosition = this.mouse
        if (!this.traceInteractable()) {
          this.traceMoveToPoint()
        }
        event.preventDefault()
      }

      this.lastTap = currentTime
      this.pointerState = EPointerState.UP
    }
  }

  onKeyDown = (event: KeyboardEvent) => {
    this.onKeyChanged(event, true)
  }

  onKeyUp = (event: KeyboardEvent) => {
    this.onKeyChanged(event, false)
  }

  onKeyChanged = (event: KeyboardEvent, isDown: boolean) => {
    switch (event.keyCode) {
      case 38: // up
      case 87: // w
        this.moveForward = isDown
        break

      case 37: // left
      case 65: // a
        this.moveLeft = isDown
        break

      case 40: // down
      case 83: // s
        this.moveBackward = isDown
        break

      case 39: // right
      case 68: // d
        this.moveRight = isDown
        break
    }
  }

  checkDirectionCollision(direction: THREE.Vector3) {
    const dir = direction.clone()
    const pos = new Vector3()
    pos.copy(this.camera.position)
    pos.y -= this.height - this.stepHeight
    this.raycaster.set(pos, dir)

    const eulerLeft = new THREE.Euler(0, this.yCollisionAngle, 0, "XYZ")
    const eulerRight = new THREE.Euler(0, -this.yCollisionAngle, 0, "XYZ")
    const leftDir = new THREE.Vector3()
    const rightDir = new THREE.Vector3()

    leftDir.copy(dir)
    rightDir.copy(dir)

    leftDir.applyEuler(eulerLeft)
    rightDir.applyEuler(eulerRight)

    const intersections = this.getIntersections(
      pos,
      direction,
      this.collisionIntersectionDistance,
      3,
    )
    const intersectionsLeft = this.getIntersections(
      pos,
      leftDir,
      this.collisionIntersectionDistance,
      3,
    )
    const intersectionsRight = this.getIntersections(
      pos,
      rightDir,
      this.collisionIntersectionDistance,
      3,
    )

    if (
      intersections.length > 0 ||
      intersectionsLeft.length > 0 ||
      intersectionsRight.length > 0
    ) {
      return true
    }
    return false
  }

  getIntersections(
    pos: THREE.Vector3,
    direction: THREE.Vector3,
    collisionIntersectionDistance: number,
    layer: number,
  ): THREE.Intersection[] {
    return this.pointerRaycaster.traceAll(
      pos,
      direction,
      collisionIntersectionDistance,
      layer,
    )
  }

  traceMoveToPoint() {
    const currentPlayerPosition = this.camera.position
    const playerPosition = new THREE.Vector3(
      currentPlayerPosition.x,
      currentPlayerPosition.y - this.height,
      currentPlayerPosition.z,
    )
    const walkable = this.pointerRaycaster.traceFromCameraByTagsWithDepth(
      this.mouse,
      [this.walkableTag],
      20,
      3,
      1,
    )
    if (walkable !== undefined) {
      this.moveToPosition = walkable?.point

      if (isMobile) {
        this.handleWalkCircle(walkable)
      }
    } else {
      this.moveToPosition = playerPosition
    }
  }

  traceInteractable(): boolean {
    const interactable = this.pointerRaycaster.traceFromCameraByTagsWithDepth(
      this.mouse,
      [this.artPieceTag, "InfoPoint"],
      20,
      1,
      3,
    )    

    if (interactable !== undefined) {
      const interact = interactable.object

      if (interact.userData.typetag === this.videoControllsTag) {
        const videoPlayer = interact.userData.colTarget

        if (videoPlayer !== undefined && videoPlayer instanceof VideoPlayer) {
          videoPlayer?.toggleState()
        }
      } else {
        this.dispatchEvent({
          type: "interact",
          message: interact.userData,
        })
      }
      return true
    }

    const portal = this.pointerRaycaster.traceFromCameraByTag(
      this.mouse,
      "Portal",
      15,
      2,
    )

    if (portal !== undefined) {
      if (portal.object.userData.typetag === "Portal") {
        this.dispatchEvent({
          type: "interact",
          message: portal.object.userData,
        })
        return true
      }
    }

    return false
  }

  getMoveToDirection(toPosition: THREE.Vector3) {
    const distance = this.getDistanceToTargetPosition(toPosition)

    if (distance < this.minDistanceToMove) {
      return new THREE.Vector3()
    } else {
      return this.playerTargetDirection(toPosition)
    }
  }

  playerTargetDirection(toPosition: THREE.Vector3) {
    const currentPlayerPosition = this.camera.position
    const playerPosition = new THREE.Vector3(
      currentPlayerPosition.x,
      currentPlayerPosition.y - this.height,
      currentPlayerPosition.z,
    )
    const moveDirection = new THREE.Vector3()
    toPosition.y = playerPosition.y
    moveDirection.subVectors(toPosition, playerPosition).normalize()
    return moveDirection
  }

  getDistanceToTargetPosition(toPosition: THREE.Vector3) {
    const currentPlayerPosition = this.camera.position
    const playerPosition = new THREE.Vector3(
      currentPlayerPosition.x,
      currentPlayerPosition.y - this.height,
      currentPlayerPosition.z,
    )
    const distance = playerPosition.distanceTo(toPosition)
    return distance
  }

  handleCursor() {
    if (this.pointerState == EPointerState.DOWN) {
      const distance = this.mouse.distanceTo(this.downMousePosition)

      if (distance >= this.minDistanceToDragCursor) {
        this.pointerState = EPointerState.DRAG
      }
    }

    if (this.pointerState === EPointerState.DRAG) {
      this.circle.visible = false
      this.ChangeCursor("grab-cursor")
    } else {
      let tag = ""

      const interactable = this.pointerRaycaster.traceFromCameraByTagsWithDepth(
        this.mouse,
        [this.artPieceTag],
        20,
        1,
        3,
      )

      const floor = this.pointerRaycaster.traceFromCameraByTagsWithDepth(
        this.mouse,
        [
          this.walkableTag,
          this.artPieceTag,
          this.videoTag,
          this.videoControllsTag,
        ],
        20,
        1,
        1,
      )
      
      if (floor) {
        tag = floor.object.userData.tag
      }

      if (interactable) {
        tag = interactable.object.userData.tag
      }


      switch (tag) {
        case this.walkableTag:
          this.ChangeCursor(this.walkableCursor)
          this.circle.visible = true //!isMobile ? true : false
          break
        case this.artPieceTag:
        case this.videoControllsTag:
          this.ChangeCursor(this.searchCursor)
          if (!isMobile) {
            this.circle.visible = false
          }
          break
        default:
          this.ChangeCursor(this.handCursor)
          if (!isMobile) {
            this.circle.visible = false
          }
          break
      }
    }
  }

  ChangeCursor(cursorClass: string) {
    if (this.canvas.classList.contains(cursorClass)) {
      return
    }
    this.canvas.classList.remove(...this.canvas.classList)
    this.canvas.classList.add(cursorClass)
  }

  update() {
    this.updateTimeDelta = this.clock.getDelta()
    this.updateTime += this.updateTimeDelta
    let walkable: any

    this.handleCursor()
    if (this.pointerState != EPointerState.DRAG) {
      this.rotateCamera()
    }

    let newDirection = new THREE.Vector3()

    if (!isMobile) {
      walkable = this.pointerRaycaster.traceFromCameraByTag(
        this.mouse,
        this.walkableTag,
        20,
        1,
      )
    }
    this.handleWalkCircle(walkable)

    if (
      this.pointerState == EPointerState.UP ||
      this.pointerState == EPointerState.NONE
    ) {
      let interactable: any = undefined
      if (!isMobile) {
        interactable = this.pointerRaycaster.traceFromCameraByTagsWithDepth(
          this.mouse,
          [this.artPieceTag, "Portal", "InfoPoint"],
          15,
          1,
          3,
        )
      } else {
        interactable = this.pointerRaycaster.traceCameraDirByTagWithDepth(
          [this.artPieceTag, "Portal", "InfoPoint"],
          5,
          1,
          3,
        )
      }

      if (interactable === undefined) {
        const portal = this.pointerRaycaster.traceFromCameraByTagsWithDepth(
          this.mouse,
          ["Portal"],
          15,
          1,
          2,
        )

        if (portal !== undefined) {
          interactable = portal
        }
      }

      if (interactable !== undefined) {
        this.dispatchEvent({
          type: "onInteractableHover",
          message: interactable.object,
        })

        if (
          interactable.object.userData.typetag === this.videoTag ||
          interactable.object.userData.typetag === this.videoControllsTag
        ) {
          let videoPlayer = interactable.object.userData.videoTarget
          this.dispatchEvent({
            type: "onInteractableHoverVideo",
            message: interactable.object,
          })
          if (videoPlayer === undefined) {
            videoPlayer = this.scene.getObjectByName(
              interactable.object.userData.file,
            ) as VideoPlayer
          }

          if (videoPlayer !== undefined && videoPlayer instanceof VideoPlayer) {
            this.lastPlayer?.onHoverExit()
            videoPlayer?.onHoverEnter()
            this.lastPlayer = videoPlayer
          }
        } else {
          if (
            this.lastPlayer !== undefined &&
            this.lastPlayer instanceof VideoPlayer
          ) {
            this.lastPlayer?.onHoverExit()
          }

          if(interactable.object.userData.typetag === "InfoPoint"){
            this.dispatchEvent({
              type: "onInteractableHover",
              message: interactable.object,
            })
          }
        }
      } else {
        if (
          this.lastPlayer !== undefined &&
          this.lastPlayer instanceof VideoPlayer
        ) {
          this.lastPlayer?.onHoverExit()
          this.dispatchEvent({
            type: "onInteractableHoverEndVideo",
          })
        }
        this.dispatchEvent({
          type: "onInteractableHoverEnd",
        })
      }
    } else {
      if (this.lastPlayer !== undefined) {
        this.lastPlayer?.onHoverExit()
      }
      this.dispatchEvent({
        type: "onInteractableHoverEnd",
      })
    }

    if (
      !this.moveBackward &&
      !this.moveForward &&
      !this.moveLeft &&
      !this.moveRight
    ) {
      if (this.getDistanceToTargetPosition(this.moveToPosition) > 1) {
        this.direction.z = this.getMoveToDirection(this.moveToPosition).z
        this.direction.x = this.getMoveToDirection(this.moveToPosition).x
      }

      newDirection = this.direction
      newDirection.y = 0
      newDirection.normalize()

      if (this.getDistanceToTargetPosition(this.moveToPosition) <= 1) {
        this.speed -= this.updateTimeDelta * this.acceleration
        if (this.speed < 0) {
          this.speed = 0
          if (isMobile) {
            this.circle.visible = false
          }
        }
      } else {
        if (this.speed < this.moveSpeed) {
          this.speed += this.updateTimeDelta * this.acceleration
        }
      }
    } else {
      this.direction.z = Number(this.moveForward) - Number(this.moveBackward)
      this.direction.x = Number(this.moveRight) - Number(this.moveLeft)

      if (this.speed < this.moveSpeed) {
        this.speed += this.updateTimeDelta * this.acceleration
      }

      this.moveToPosition.subVectors(
        this.camera.position,
        new THREE.Vector3(0, this.height, 0),
      )

      this.direction.normalize()
      newDirection = this.direction
      newDirection.z = -newDirection.z
      newDirection.applyQuaternion(this.camera.quaternion)
      newDirection.y = 0
      newDirection.normalize()
    }

    if (this.checkDirectionCollision(newDirection)) {
      newDirection.x = 0
      newDirection.z = 0
      this.moveToPosition.subVectors(
        this.camera.position,
        new THREE.Vector3(0, this.height, 0),
      )
    }

    const grounded = this.pointerRaycaster.traceAll(
      this.camera.position.clone().add(new Vector3(0, 0.5, 0)),
      new THREE.Vector3(0, -1, 0),
      10,
      1,
    )

    this.camera.position.addScaledVector(
      newDirection,
      this.updateTimeDelta * this.speed,
    )

    if (grounded.length > 0) {
      const yDistance = Math.abs(this.previousPosition.y - grounded[0].point.y)
      if (yDistance > this.yDamping) {
        this.camera.position.y = grounded[0].point.y + this.height
      } else {
        this.camera.position.y = this.previousPosition.y + this.height
      }
      this.previousPosition = grounded[0].point
    }

    this.cameraTargetPosition.copy(this.camera.position)
    const cameraDirection = new Vector3()

    this.camera.getWorldDirection(cameraDirection)
  }

  handleWalkCircle(walkable: any) {
    if (walkable !== undefined) {
      if (walkable.object) {
        const mesh = walkable.object as THREE.Mesh
        if (
          mesh.userData.name != undefined &&
          mesh.userData.name == "Move_Cursor"
        ) {
          const distance = new Vector3()
          distance.subVectors(this.camera.position, walkable.point)
          const dist = distance.length()

          const distanceFeet = new Vector3()
          distanceFeet.subVectors(this.previousPosition, walkable.point)
          distanceFeet
            .normalize()
            .multiplyScalar(
              this.circleSize *
                (<PerspectiveCamera>this.camera).fov *
                dist *
                0.15,
            )

          console.log(distanceFeet)

          this.circle.scale.set(
            dist * this.circleSize * (<PerspectiveCamera>this.camera).fov,
            dist * this.circleSize * (<PerspectiveCamera>this.camera).fov,
            dist * this.circleSize * (<PerspectiveCamera>this.camera).fov,
          )

          if (isMobile) {
            this.dispatchEvent({
              type: "onDoubleTapped",
              message: true,
            })
            this.circle.position.set(
              walkable.point.x,
              walkable.point.y + this.walkableYAxisPlayerOffset,
              walkable.point.z,
            )
          } else {
            this.circle.position.set(
              walkable.point.x, // - distanceFeet.x,
              walkable.point.y + this.walkableYAxisPlayerOffset,
              walkable.point.z, // - distanceFeet.z,
            )
          }
        } else {
          this.dispatchEvent({
            type: "onDoubleTapped",
            message: true,
          })
          const distance = new Vector3()
          distance.subVectors(this.camera.position, walkable.point)
          const dist = distance.length()

          const distanceFeet = new Vector3()
          distanceFeet.subVectors(this.previousPosition, walkable.point)
          distanceFeet
            .normalize()
            .multiplyScalar(
              this.circleSize *
                (<PerspectiveCamera>this.camera).fov *
                dist *
                0.15,
            )

          this.circle.position.set(
            walkable.point.x, // + distanceFeet.x,
            walkable.point.y + this.walkableYAxisPlayerOffset,
            walkable.point.z, // + distanceFeet.z,
          )

          if (walkable.face !== null && walkable.face !== undefined) {
            
            const n = walkable.face.normal.clone()
            n.transformDirection(walkable.object.matrixWorld)
            n.multiplyScalar(10)
            n.add(walkable.point)
            this.circle.lookAt(n)
          }
        }
      }
    }
    const distance = new Vector3()
    distance.subVectors(this.camera.position, this.circle.position)
    const dist = distance.length()

    this.circle.scale.set(
      dist * this.circleSize * (<PerspectiveCamera>this.camera).fov,
      dist * this.circleSize * (<PerspectiveCamera>this.camera).fov,
      dist * this.circleSize * (<PerspectiveCamera>this.camera).fov,
    )
  }
}
