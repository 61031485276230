import { isMobile } from "react-device-detect"
import * as THREE from "three"
import { RGBFormat, RGBM7Encoding, sRGBEncoding } from "three"

export class VideoPlayer extends THREE.Object3D {
  videoName: string
  videoSrc: string
  videoId: string
  videoMesh: THREE.Mesh
  scene: THREE.Group
  material: THREE.MeshPhysicalMaterial

  playTexture: THREE.Object3D
  pauseTexture: THREE.Object3D
  videoControlls: THREE.Object3D

  video: HTMLVideoElement
  isPlaying = false
  private loop = false
  private restartOnPlay = false
  private setFirstFrame = false

  //tags
  private artPieceTag = "ArtPiece"
  private videoTag = "Video"

  //endpoints
  private playCircleEndPoint = "/icons/play_button.png"
  private pauseCircleEndPoint = "/icons/pause_button.png"

  //plane
  private planeWidth = 0.36
  private planeHeight = 0.2
  private planeWidthSegments = 32

  constructor(
    videoName: string,
    videoSrc: string,
    videoId: string,
    videoMesh: THREE.Mesh,
    material: THREE.MeshPhysicalMaterial,
    scene: THREE.Group,
    setFirstFrame = false,
  ) {
    super()

    this.setFirstFrame = setFirstFrame
    this.videoName = videoName

    this.videoSrc = videoSrc
    this.videoId = videoId
    this.videoMesh = videoMesh
    this.material = material
    this.scene = scene
    this.video = document.createElement("video")
    this.video.id = this.videoId
    this.video.src = this.videoSrc
    // this.video.muted = true
    this.video.crossOrigin = "anonymous"
    this.video.preload = "auto"
    this.video.playsInline = true
    this.video.setAttribute("playsinline", "true")
    this.video.preload = "auto"
    // this.video.autoplay = true
    this.userData.videoPlayer = "VIDEO PLAYER!!!"
    this.video.load()

    const box_geometry = new THREE.BoxGeometry(
      this.planeWidth,
      this.planeWidth,
      this.planeWidth,
    )
    const box_material = new THREE.MeshBasicMaterial({ color: 0x00ff00 })
    box_material.wireframe = true
    const cube = new THREE.Mesh(box_geometry, box_material)
    cube.userData.typetag = "VideoControlls"
    cube.userData.player = this

    this.scene.add(cube)

    const pauseIcons = new THREE.PlaneGeometry(
      this.planeWidth,
      this.planeHeight,
      this.planeWidthSegments,
    )
    const pauseMat = new THREE.MeshLambertMaterial()
    const pausePlane = new THREE.Mesh(pauseIcons, pauseMat)

    const playIcons = new THREE.PlaneGeometry(
      this.planeWidth,
      this.planeHeight,
      this.planeWidthSegments,
    )
    const playMat = new THREE.MeshLambertMaterial()
    const playPlane = new THREE.Mesh(playIcons, playMat)

    const loader = new THREE.TextureLoader()
    loader.load(
      // resource URL
      window.location.origin + this.pauseCircleEndPoint,
      // called when the resource is loaded
      function (data) {
        pauseMat.transparent = true
        pauseMat.depthWrite = false
        pauseMat.side = THREE.DoubleSide
        pauseMat.map = data
        pauseMat.alphaTest = 0.5
        pauseMat.emissiveMap = data
        pauseMat.emissiveIntensity = 1
        pauseMat.emissive = new THREE.Color(1, 1, 1)
      },
    )

    loader.load(
      // resource URL
      window.location.origin + this.playCircleEndPoint,
      // called when the resource is loaded
      function (data) {
        playMat.transparent = true
        playMat.depthWrite = false
        playMat.side = THREE.DoubleSide
        playMat.map = data
        playMat.alphaTest = 0.5
        playMat.emissiveMap = data
        playMat.emissiveIntensity = 1
        playMat.emissive = new THREE.Color(1, 1, 1)
      },
    )

    this.videoControlls = cube
    this.videoControlls.layers.set(3)

    this.playTexture = playPlane
    this.pauseTexture = pausePlane

    this.playTexture.visible = false
    this.pauseTexture.visible = false

    this.scene.add(pausePlane)
    this.scene.add(playPlane)
    this.pauseTexture.layers.enable(0)
    this.playTexture.layers.enable(0)

    this.video.onended = () => {
      this.isPlaying = false
    }

    this.scene.add(this)
  }

  setLoop(val: boolean) {
    this.loop = val
    this.video.loop = val
  }

  setRestartOnPlay(val: boolean) {
    this.restartOnPlay = val
    if (val) {
      this.video.addEventListener(
        "play",
        function () {
          this.currentTime = 3
        },
        false,
      )
    } else {
      this.video.removeEventListener(
        "play",
        function () {
          return
        },
        false,
      )
    }
  }

  init() {
    this.video.currentTime = 3
    this.setRestartOnPlay(this.restartOnPlay)
    this.setLoop(this.loop)
    if (this.setFirstFrame) {
      const texture = new THREE.VideoTexture(this.video)
      texture.encoding = sRGBEncoding
      texture.format = RGBFormat
      texture.needsUpdate = true
      this.material.map = texture
      this.material.emissiveMap = texture
      this.material.emissiveIntensity = 1
      this.material.needsUpdate = true
    }

    if (this.videoMesh == undefined) {
      const geometry = new THREE.SphereBufferGeometry(500, 60, 40)
      geometry.scale(-1, 1, 1)
      const mesh = new THREE.Mesh(geometry, this.material)
      this.scene.add(mesh)
    }

    this.videoMesh.attach(this.playTexture)
    this.videoMesh.attach(this.pauseTexture)
    this.videoMesh.parent?.attach(this.videoControlls)

    this.videoControlls.position.set(0, 0, 0)
    this.videoControlls.quaternion.copy(this.videoMesh.quaternion)

    this.videoMesh.userData = {
      typetag: this.videoTag,
      tag: this.artPieceTag,
      name: this.videoName,
    }
    const nameParse = this.videoName.split("/")
    this.name = nameParse[nameParse.length - 1].split(".")[0]

    this.videoControlls.userData = this.videoMesh.userData
    this.videoControlls.userData.typetag = "VideoControlls"
    this.videoControlls.userData.file = this.name

    this.videoControlls.userData.colTarget = this as VideoPlayer
    //this.video.muted = true;
    // this.play()
  }

  toggleState() {
    if (this.isPlaying === true) {
      this.pause()
    } else {
      this.play()
    }
  }

  onHoverEnter() {
    let current = this.pauseTexture
    if (!isMobile) {
      if (this.isPlaying) {
        current = this.pauseTexture
        this.pauseTexture.visible = true
        this.playTexture.visible = false
      } else {
        current = this.playTexture
        this.pauseTexture.visible = false
        this.playTexture.visible = true
      }
      current.visible = true
    }

    this.videoMesh.parent?.attach(this.videoControlls)

    const vector = new THREE.Vector3(0, 0, -1)
    const offset = vector.multiplyScalar(0.02)
    current.position.set(offset.x, offset.y, offset.z)
    if (!isMobile) {
      this.videoControlls.rotation.copy(this.videoMesh.rotation)
      this.videoControlls.position.copy(this.videoMesh.position)
    }
  }

  setVideoColliderPosition = () => {
    this.videoControlls.position.copy(this.videoMesh.position)
  }

  onHoverExit() {
    this.playTexture.visible = false
    this.pauseTexture.visible = false
    return undefined
  }

  play() {
    if (this.videoMesh.material !== this.material) {
      const texture = new THREE.VideoTexture(this.video)
      texture.format = RGBFormat
      texture.encoding = sRGBEncoding
      texture.needsUpdate = true
      this.material.map = texture
      this.material.emissiveMap = texture
      this.material.emissiveIntensity = 1
      this.material.needsUpdate = true
      this.videoMesh.material = this.material
    }
    console.log(this.video)
    this.video.play().then(() => {
      this.isPlaying = true
    })
  }

  pause() {
    this.isPlaying = false
    this.video.pause()
  }
}
