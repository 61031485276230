import React, { useEffect, useRef, useState } from "react"
import { useHistory, useRouteMatch } from "react-router-dom"
import * as THREE from "three"
import Stats from "../../Helpers/Stats/Stats"
import { getMesh } from "../../Helpers/requests"
import { parseQuery } from "../../Helpers/functions"
import { iQualityReduction, iReduction } from "../../definitions"
import SceneLoader from "../../Loaders/SceneLoader/SceneLoader"
import { OrbitControls } from "../../Controls/OrbitControl/OrbitControl"
import { TransformControls } from "../../Controls/TransformControls/TransformControls"
import { useScene } from "../../hooks/useScene"
import { EndlessEnvironment } from "../../Environment/EndlessEnvironment"
import { repositionCamera } from "../../Helpers/three"
import Loader from "../../Components/Loaders/Loader"
import { API_URL } from "../../api/Definitions"
import { isMobile } from "react-device-detect"
import { Color, DirectionalLight } from "three"

const Artifact = () => {
  const [isLoading, setLoading] = useState(true)
  const [progress, setProgress] = useState(0)
  const container = useRef<HTMLDivElement>(null)
  const match = useRouteMatch<{ url: string }>()
  const history = useHistory()

  const { renderer, scene } = useScene({ container })

  const mobileResolution = 512
  const pcResolution = 2048

  //camera
  const fieldOfView = 60
  const near = 0.1
  const far = 100

  useEffect(() => {
    const { light } = EndlessEnvironment(scene)

    //Create a plane that receives shadows (but does not cast them)
    const camera = new THREE.PerspectiveCamera(
      fieldOfView,
      window.innerWidth / window.innerHeight,
      near,
      far,
    )

    const group = new THREE.Group()
    group.scale.set(1, 1, -1)
    group.position.set(0, 0, 0)
    scene.add(group)

    const query = parseQuery(history.location.search)

    // const transform = new TransformControls(camera, renderer.domElement)
    // transform.attach(light)
    // transform.addEventListener("dragging-changed", function (event) {
    //   controls.enabled = !event.value
    // })

    // scene.add(transform)

    light.intensity = 0.8

    // const dirLight = new DirectionalLight(0xffffff, 0.5)
    // dirLight.castShadow = true
    // scene.add(dirLight)

    const controls = new OrbitControls(camera, renderer.domElement)
    controls.enablePan = true
    controls.enableDamping = true // an animation loop is required when either damping or auto-rotation are enabled
    controls.dampingFactor = 0.1
    controls.maxPolarAngle = Math.PI / 2

    console.log("mesh")

    const additionalLight = new THREE.SpotLight( 0xffffff, 0.5, 100 );
    additionalLight.parent = camera;
    additionalLight.position.set(camera.position.x, camera.position.y, camera.position.z);
    additionalLight.rotation.set(camera.rotation.x, camera.rotation.y, camera.rotation.z);
    scene.add( additionalLight );

    //start loading scene geometry
    getMesh({
      link: `${API_URL}/v1/${query.draft ? "draft" : "public"}-artifacts/${
        match.params.url
      }`,
      query,
      responseType: "arraybuffer",
    }).then(({ json }) => {
      const sceneLoader = new SceneLoader({
        json,
        renderer: renderer,
        group,
        root: "https://content.vrallart.com",
        reduction: new iQualityReduction(iReduction.x1, 2048),
        resetPosition: true,
        onProgress: (val) => {
          setProgress(val)
        },
      })

      const node = json.nodes && json.nodes[0] && json.nodes[0]
      if (node) {
        const dLight = light as THREE.DirectionalLight
        const scaleFactor = node.size.y < 2 ? 2 / node.size.y : 1

        group.scale.multiplyScalar(scaleFactor)
        const bounds = new THREE.Vector3(
          node.size.x,
          node.size.y,
          node.size.z,
        ).multiplyScalar(scaleFactor)

        dLight.position.set(
          bounds.y / 3,
          (bounds.y / 3) * 2,
          -(bounds.y / 3) * 2,
        )

        controls.target = new THREE.Vector3(0, bounds.y / 2, 0)

        repositionCamera({
          camera,
          bounds,
        })
      }

      sceneLoader.Load().then(() => {
        sceneLoader.ReorderObjects()
        sceneLoader.LoadPass(
          isMobile
            ? new iQualityReduction(iReduction.x1, mobileResolution)
            : new iQualityReduction(iReduction.x1, pcResolution),
          true,
        )
        setLoading(false)
        scene.background = new Color(0x303336)
      })
    })

    // const stats = Stats()
    // container.current?.appendChild(stats.dom)

    window.addEventListener("resize", onWindowResize, false)

    function onWindowResize() {
      console.log("onresize")

      camera.aspect = window.innerWidth / window.innerHeight
      camera.updateProjectionMatrix()

      renderer.setSize(window.innerWidth, window.innerHeight)
    }

    controls.update()

    function animate() {
      requestAnimationFrame(animate)

      controls.update()
      renderer.render(scene as THREE.Scene, camera)
      additionalLight.position.set(camera.position.x, camera.position.y, camera.position.z);
      additionalLight.rotation.set(camera.rotation.x, camera.rotation.y, camera.rotation.z);
      // stats.update()
    }
    animate()
  }, [])

  return (
    <div ref={container} className="App">
      {isLoading && <Loader progress={progress} />}
    </div>
  )
}

export default Artifact
