import React, { useEffect, useRef, useState } from "react"
import { useHistory, useRouteMatch } from "react-router-dom"
import { ReactComponent as Close } from "../../Components/Icons/close.svg"
import * as THREE from "three"
import Stats from "../../Helpers/Stats/Stats"
import { Color, sRGBEncoding } from "three"
import { get } from "../../Helpers/requests"
import { parseQuery } from "../../Helpers/functions"
import {
  iArtpiece,
  iMeshJson,
  iQualityReduction,
  iReduction,
} from "../../definitions"
import SceneLoader from "../../Loaders/SceneLoader/SceneLoader"
import { OrbitControls } from "../../Controls/OrbitControl/OrbitControl"
import { API_URL, CONTENT_URL } from "../../api/Definitions"

const ArtPiece = () => {
  const [isLoading, setLoading] = useState(true)
  const [progress, setProgress] = useState(0)
  const [showIframe, setShowIframe] = useState(false)
  const [iframeSource, setIframe] = useState("")

  const scene = useRef<THREE.Scene>()
  const renderer = useRef<THREE.WebGLRenderer>()
  const match = useRouteMatch<{ url: string }>()
  const history = useHistory()

  //camera
  const fieldOfView = 60;
  const near = 0.1;
  const far = 10000;

  //roots
  const sceneRoot = "https://content.vrallart.com";

  const artPieceHeightOffset = 10;

  const highQualityResolution = 4096;

  useEffect(() => {
    const container = document.getElementById("stats")

    const stats = Stats()
    container?.appendChild(stats.dom)

    if (!scene.current) {
      scene.current = new THREE.Scene()
    }

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5)
    directionalLight.position.set(20, 20, 20)
    scene.current.add(directionalLight)

    const canvas = document.getElementById("canvas") as HTMLCanvasElement
    const camera = new THREE.PerspectiveCamera(
      fieldOfView,
      window.innerWidth / window.innerHeight,
      near,
      far,
    )

    renderer.current = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
      canvas: canvas,
      powerPreference: "high-performance",
    })

    renderer.current.setPixelRatio(window.devicePixelRatio)
    renderer.current.toneMapping = THREE.ReinhardToneMapping
    renderer.current.toneMappingExposure = Math.pow(1, 4.0)
    renderer.current.outputEncoding = sRGBEncoding

    renderer.current.setSize(window.innerWidth, window.innerHeight - artPieceHeightOffset)

    const group = new THREE.Group()
    group.scale.set(1, 1, -1)
    group.position.set(0, 0, 0)
    scene.current.add(group)
    scene.current.background = new Color(0xb6b6b6)

    const query = parseQuery(history.location.search)

    const controls = new OrbitControls(camera, canvas)

    //start loading scene geometry
    get<iArtpiece>({
      link: `${API_URL}/v1/${query.draft ? "draft" : "public"}-artpieces/${
        match.params.url
      }`,
      query,
    }).then((artifact) => {
      get<iMeshJson>({
        link: `${CONTENT_URL}meshes/${artifact.meshguid}/${artifact.meshguid}.json`,
      }).then((json) => {
        console.log(json)

        const sceneLoader = new SceneLoader({
          json,
          renderer: renderer.current as THREE.WebGLRenderer,
          group,
          root: sceneRoot,
          reduction: new iQualityReduction(iReduction.x1, highQualityResolution),
          resetPosition: true,
          onProgress: (val) => {
            console.log(val)
            setProgress(val)
          },
        })

        const node = json.nodes && json.nodes[0] && json.nodes[0]
        if (node) {
          const bounds = new THREE.Box3(
            new THREE.Vector3(-node.size.x, -node.size.y, -node.size.z),
            node.size,
          )
          const tmp = new THREE.Vector3()
          bounds.getCenter(tmp)
          group.position.set(tmp.x, tmp.y, tmp.z)

          const fov = Math.sin(camera.getEffectiveFOV() * (Math.PI / 180))
          const size = Math.max(node.size.x, node.size.y, node.size.z)
          const distance = Math.abs(size / Math.sin(fov))

          const vec = camera.position.normalize().addScalar(distance)
          camera.position.set(vec.x, vec.y, vec.z)
        }

        sceneLoader.Load().then(() => {
          sceneLoader.ReorderObjects()
          setLoading(false)
        })
      })
    })
    controls.target = group.position

    controls.update()
    function animate() {
      requestAnimationFrame(animate)
      controls.update()
      renderer.current?.render(scene.current as THREE.Scene, camera)
      stats.update()
    }
    animate()
  }, [])

  return (
    <div className="App">
      <canvas id="canvas"></canvas>
      {showIframe && (
        <div>
          <div className="close">
            <Close />
          </div>
          <iframe src={iframeSource} frameBorder="0"></iframe>
        </div>
      )}
      <div id="stats"></div>
      {isLoading && (
        <div className="loader">
          <h1 className="loading">{(progress * 100).toFixed(0)}</h1>
        </div>
      )}
    </div>
  )
}

export default ArtPiece
