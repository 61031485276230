import * as THREE from "three"
import { Vector3 } from "three"
export class PointerRaycast {
  camera: THREE.Camera
  domElement: HTMLCanvasElement | undefined
  traceDistance = 0
  scene: THREE.Scene
  raycaster: THREE.Raycaster
  constructor(
    camera: THREE.Camera,
    traceDistance: number,
    scene: THREE.Scene,
    domElement?: HTMLCanvasElement,
  ) {
    this.camera = camera
    this.domElement = domElement
    this.traceDistance = traceDistance
    this.scene = scene
    this.raycaster = new THREE.Raycaster()
  }
  traceFromCamera(mouse: THREE.Vector2, far = 1) {
    this.raycaster.setFromCamera(mouse, this.camera)
    this.raycaster.far = far
    const intersects = this.raycaster.intersectObjects(
      this.scene.children,
      true,
    )
    this.raycaster.far = 1
    return intersects
  }
  traceAll(
    position: THREE.Vector3,
    direction: THREE.Vector3,
    far = 1,
    layer = 1,
  ) {
    this.raycaster.set(position, direction)
    this.raycaster.far = far
    this.raycaster.layers.set(layer)
    const intersects = this.raycaster.intersectObjects(
      this.scene.children,
      true,
    )
    this.raycaster.far = far

    const intersects2 = []
    for (let i = 0; i < intersects.length; i++) {
      if (intersects[i].object.userData.interactable !== false) {
        intersects2.push(intersects[i])
      }
    }

    return intersects2
  }
  traceFirst(position: THREE.Vector3, direction: THREE.Vector3, far = 1) {
    this.raycaster.set(position, direction)
    this.raycaster.far = far
    const intersects = this.raycaster.intersectObjects(
      this.scene.children,
      false,
    )
    this.raycaster.far = far
    return intersects
  }
  traceFromCameraFirst(position: THREE.Vector2, far = 1, layer = 0) {
    this.raycaster.setFromCamera(position, this.camera)
    this.raycaster.far = far
    this.raycaster.layers.set(layer)
    const intersects = this.raycaster.intersectObjects(
      this.scene.children,
      true,
    )
    console.log(intersects)
    if (intersects.length > 0) return intersects[0]
    else return undefined
  }

  traceFromCameraByTag(mouse: THREE.Vector2, tag: string, far = 1, layer = 1) {
    this.raycaster.setFromCamera(mouse, this.camera)
    this.raycaster.far = far
    this.raycaster.layers.set(layer)
    const intersects = this.raycaster.intersectObjects(
      this.scene.children,
      true,
    )
    this.raycaster.far = far
    if (intersects.length > 0) {
      if (intersects[0].object.userData.tag === tag && intersects[0].object.userData.interactable !== false) {
        return intersects[0]
      }
    }
  }

  traceFromCameraByTagWithOffset(
    mouse: THREE.Vector2,
    tag: string,
    far = 1,
    layer = 1,
    offset: THREE.Vector3,
    offsetDistance: number,
  ) {
    this.raycaster.setFromCamera(mouse, this.camera)
    this.raycaster.far = far
    this.raycaster.layers.set(layer)
    const intersects = this.raycaster.intersectObjects(
      this.scene.children,
      true,
    )
    this.raycaster.far = far
    if (intersects.length > 0) {
      if (intersects[0].object.userData.tag === tag && intersects[0].object.userData.interactable !== false) {
        return intersects[0]
      }
    }
  }

  traceFromCameraByTags(mouse: THREE.Vector2, tags: string[], far = 1) {
    this.raycaster.setFromCamera(mouse, this.camera)
    this.raycaster.far = far
    const intersects = this.raycaster.intersectObjects(
      this.scene.children,
      true,
    )
    this.raycaster.far = far
    if (intersects.length > 0) {
      if (tags.includes(intersects[0].object.userData.tag) && intersects[0].object.userData.interactable !== false) {
        return intersects[0]
      }
    }
  }
  traceFromCameraByTagsWithDepth(
    mouse: THREE.Vector2,
    tags: string[],
    far = 1,
    depth = 1,
    layer = 1,
  ) {
    this.raycaster.setFromCamera(mouse, this.camera)
    this.raycaster.far = far
    this.raycaster.layers.set(layer)
    const intersects = this.raycaster.intersectObjects(
      this.scene.children,
      true,
    )
    
    this.raycaster.far = far
    for (let i = 0; i < depth; i++)
      if (intersects.length > i) {
        if (tags.includes(intersects[i].object.userData.tag) && intersects[i].object.userData.interactable !== false) {
          return intersects[i]
        }
      }
  }
  traceCameraDirByTagWithDepth(tags: string[], far = 1, depth = 1, layer = 0) {
    const direction = new Vector3(0, 0, 0)
    this.camera.getWorldDirection(direction)
    
    this.raycaster.set(this.camera.position, direction)
    this.raycaster.far = far
    this.raycaster.layers.set(layer)
    const intersects = this.raycaster.intersectObjects(
      this.scene.children,
      true,
    )

    this.raycaster.far = far
    for (let i = 0; i < depth; i++) {
      if (intersects.length > i) {
        if (tags.includes(intersects[i].object.userData.tag) && intersects[i].object.userData.interactable !== false) {
          return intersects[i]
        }
      }
    }
    return undefined
  }

  traceFromCameraAllByTag(
    mouse: THREE.Vector2,
    tag: string,
    far = 1,
    layer = 0,
  ) {
    this.raycaster.setFromCamera(mouse, this.camera)
    this.raycaster.far = far
    this.raycaster.layers.set(layer)
    const intersects = this.raycaster.intersectObjects(
      this.scene.children,
      true,
    )
    this.raycaster.far = far
    if (intersects.length > 0) {
      const intersect = intersects.find(
        (i) => i.object.userData !== undefined && i.object.userData.tag === tag && i.object.userData.interactable !== false,
      )
      if (intersect) {
        return intersect
      } else {
        return undefined
      }
    }
  }

  traceCameraDirByTags(tags: string[], far = 1, layer = 0) {
    const direction = new Vector3(0, 0, -1)
    this.camera.getWorldDirection(direction)

    this.raycaster.set(this.camera.position, direction)
    this.raycaster.far = far
    this.raycaster.layers.set(layer)
    const intersects = this.raycaster.intersectObjects(
      this.scene.children,
      true,
    )
    this.raycaster.far = far
    if (intersects.length > 0) {
      if (tags.includes(intersects[0].object.userData.tag) && intersects[0].object.userData.interactable !== false) {
        console.log(intersects[0].object.userData.tag)
        return intersects[0]
      }
    }
    return undefined
  }
  traceCameraDirByTag(tag: string, far = 1, layer = 0) {
    const direction = new Vector3(0, 0, 0)
    this.camera.getWorldDirection(direction)
    this.raycaster.set(this.camera.position, direction)
    this.raycaster.far = far
    this.raycaster.layers.set(layer)
    const intersects = this.raycaster.intersectObjects(
      this.scene.children,
      true,
    )
    this.raycaster.far = far
    if (intersects.length > 0) {
      if (intersects[0].object.userData.tag === tag && intersects[0].object.userData.interactable !== false) {
        return intersects[0]
      }
    }
    return undefined
  }
  traceByTag(
    position: THREE.Vector3,
    direction: THREE.Vector3,
    tag: string,
    far = 1,
  ) {
    this.raycaster.set(position, direction)
    this.raycaster.far = far
    const intersects = this.raycaster.intersectObjects(
      this.scene.children,
      true,
    )
    this.raycaster.far = far
    if (intersects.length > 0) {
      if (intersects[0].object.userData.tag === tag && intersects[0].object.userData.interactable !== false) {
        return intersects[0]
      }
    }
    return undefined
  }
  traceFromCameraByName(mouse: THREE.Vector2, name: string, far = 1) {
    this.raycaster.setFromCamera(mouse, this.camera)
    this.raycaster.far = this.traceDistance
    const intersects = this.raycaster.intersectObjects(
      this.scene.children,
      true,
    )
    this.raycaster.far = far
    if (intersects.length > 0) {
      if (intersects[0].object.name === name && intersects[0].object.userData.interactable !== false) {
        return intersects[0]
      }
    }
    return {}
  }
  traceByName(
    position: THREE.Vector3,
    direction: THREE.Vector3,
    name: string,
    far = 1,
  ) {
    this.raycaster.set(position, direction)
    this.raycaster.far = far
    const intersects = this.raycaster.intersectObjects(
      this.scene.children,
      true,
    )
    this.raycaster.far = far
    if (intersects.length > 0) {
      if (intersects[0].object.name === name && intersects[0].object.userData.interactable !== false) {
        return intersects[0]
      }
    }
    return {}
  }
}
