//https://github.com/endel/NativeWebSocket.git#upm

import {
  Color,
  Group,
  Material,
  Mesh,
  MeshPhysicalMaterial,
  Object3D,
  Quaternion,
  RepeatWrapping,
  RGBM7Encoding,
  Texture,
  WebGLRenderer,
  CubeTextureLoader,
  Scene,
  BoxGeometry,
  MeshBasicMaterial,
  Matrix4,
  NearestMipmapLinearFilter,
  NearestFilter,
  RGBM16Encoding,
  MeshStandardMaterial,
  DoubleSide,
  WebGLCubeRenderTarget,
  CubeCamera,
  Euler,
  LinearMipMapLinearFilter,
  Vector3,
  FrontSide,
  BufferGeometry,
  CubeTexture,
} from "three"
import {
  iMeshJson,
  iMeshData,
  iTextObject,
  iReduction,
  eTextAlignment,
  fontURLS,
  TextureQueue,
  IndexedArray,
  MeshQueue,
  Delegate,
  iSceneLoaderProps,
  iQualityReduction,
  CubemapOrder,
} from "../../definitions"
import { LoadMesh } from "./LoadMesh"
import { Text } from "troika-three-text"
import { VideoPlayer } from "../../Components/VideoPlayer/VideoPlayer"
import iconv from "iconv-lite"
import { get } from "../../Helpers/requests"
import { gunzip } from "zlib"
import { Loaders } from "../../Loaders/SceneLoader/Loaders"
import { SceneLoaderUtilities } from "../../Loaders/SceneLoader/SceneLoaderUtilities"
import { resolve } from "dns"
import { info } from "console"
export default class SceneLoader {
  json: iMeshJson
  group: Group
  cubemap: TextureQueue | undefined
  textures: IndexedArray<TextureQueue> = {}
  reflections: IndexedArray<TextureQueue> = {}
  meshes: Array<MeshQueue> = []
  renderer: WebGLRenderer
  root: string
  reduction: iQualityReduction
  lightMaps: Array<TextureQueue>
  cubemapLoader: CubeTextureLoader
  loaders: Loaders
  cubeName = ""
  resetPosition = false
  objectCount = 0
  progress = 0
  OnProgress: Delegate | undefined

  constructor({
    json,
    renderer,
    group,
    root,
    reduction,
    resetPosition = false,
    onProgress,
  }: iSceneLoaderProps) {
    this.cubemapLoader = new CubeTextureLoader()

    this.json = json
    this.group = group
    this.renderer = renderer
    this.root = root
    this.reduction = reduction
    this.lightMaps = new Array<TextureQueue>(json.lightmapDB.lightmaps.length)
    this.resetPosition = resetPosition
    this.loaders = new Loaders(this.renderer)
    this.OnProgress = onProgress

    this.objectCount +=
      json.cubemapDB.scubemap.length +
      json.lightmapDB.lightmaps.length +
      json.textureDB.stexture2D.length +
      json.reflectionDB.reflections.length +
      json.nodes.length

    this.LoadFirstPass(reduction)
  }

  LoadPass(reduction: iQualityReduction, reload = false) {
    this.progress = 0
    const empties = this.meshes.filter((x) => {
      return true
    }).length

    this.objectCount =
      this.json.cubemapDB.scubemap.length +
      this.json.lightmapDB.lightmaps.length +
      this.json.textureDB.stexture2D.length +
      this.json.reflectionDB.reflections.length +
      empties

    Promise.all([
      this.LoadLightMaps(
        this.json,
        new iQualityReduction(reduction.reduction, reduction.resolution),
      ),
      this.LoadReflections(
        this.json,
        new iQualityReduction(reduction.reduction, reduction.resolution),
      ),
      this.LoadCubemap(
        this.json,
        new iQualityReduction(reduction.reduction, 1024),
      ),
      this.LoadTextures(
        this.json,
        new iQualityReduction(reduction.reduction, reduction.reduction),
      ),
    ]).then(() => {
      if (reload) {
        this.UpdateMeshes()
      }
    })
  }

  LoadFirstPass(reduction: iQualityReduction) {
    this.LoadCubemap(this.json, reduction)
    this.LoadReflections(this.json, reduction)
    this.LoadLightMaps(this.json, reduction)
    this.LoadTextures(this.json, reduction)
  }

  //#region  textures loading

  LoadCubemap(json: iMeshJson, reduction: iQualityReduction): Promise<boolean> {
    return new Promise((resolve) => {
      //load scene cubemap

      if (this.json.cubemapDB.scubemap.length === 0) {
        console.log("cubemaps")

        const scene = this.group.parent as Scene
        scene.background = new Color(0xffff)
        resolve(true)
        return
      }
      json.cubemapDB.scubemap.forEach((scubemap) => {
        this.cubemap = new TextureQueue()

        let reductionCube = reduction.reduction
        if (reductionCube === iReduction.x1) {
          reductionCube = iReduction.original
        }
        reductionCube = iReduction.x2
        const form = scubemap.atlasFormats.find(
          (e) => e.reduction === iReduction[reductionCube],
        )
        let imagesUrls: string[][] = []

        if (form !== undefined) {
          SceneLoaderUtilities.GetTextureFromAtlasURLs(
            `${this.root}/${scubemap.filePath}/${form?.fileName}`,
            form,
            CubemapOrder.cubeMap,
          ).then((t) => {
            const tmp = this.cubemap
            imagesUrls = t
            if (tmp) {
              
              const scene = this.group.parent as Scene

              let skyboxObject = this.group.getObjectByName("Skybox")
              
              if (skyboxObject === undefined) {
                const skyboxGeo = new BoxGeometry(1, 1, 1)

                const imageArray: Material[] | Material = []

                imagesUrls[0].forEach(async (image: string) => {
                  this.LoadTextureFromUrl(image).then((t) => {
                    t.encoding = RGBM16Encoding
                    t.needsUpdate = true
                    const mat = new MeshStandardMaterial({
                      emissive: 0x808080,
                      side: DoubleSide,
                      premultipliedAlpha: true,
                    })
                    mat.emissiveMap = t
                    mat.needsUpdate = true
                    imageArray.push(mat)
                  })
                })

                skyboxObject = new Mesh(
                  skyboxGeo,
                  imageArray as Material[] | Material,
                )
                skyboxObject.name = "Skybox"
              }
              
              json.nodes.forEach((node) => {
                if (node.name === "Skylight") {
                  const rot = new Euler().setFromQuaternion(
                    new Quaternion(
                      -node?.trans.rotation.x,
                      node?.trans.rotation.y,
                      node?.trans.rotation.z,
                      -node?.trans.rotation.w,
                      ),
                    )
                    console.log(rot)
                    skyboxObject?.rotation.set(rot.x, rot.y, rot.z)

                }
              })
              skyboxObject.scale.set(1000, 1000, -1000)
              this.group.add(skyboxObject)

              this.HandleProgress()
              resolve(true)
            }
          })
        }
      })
    })
  }

  TakeCubemapScreenshot(camera: CubeCamera, renderer: WebGLRenderer, scene: Scene, renderTarget: WebGLCubeRenderTarget): Promise<CubeTexture> {
    return new Promise((resolve) => {
      camera.update(this.renderer, scene)
      scene.background = renderTarget.texture
      setTimeout(resolve, 200)
      resolve(renderTarget.texture)
    })
  }

  LoadTextureFromUrl(url: string): Promise<Texture> {
    return new Promise((resolve) => {
      const tex = Loaders.LoadTexture(url).then((t) => {
        resolve(t)
      })
    })
  }

  async LoadReflections(
    json: iMeshJson,
    reduction: iQualityReduction,
  ): Promise<boolean> {
    //load reflection cubemaps
    return new Promise((resolve) => {
      let index = 0
      this.reflections = {}
      if (json.reflectionDB.reflections.length === 0) {
        resolve(true)
        return
      }
      json.reflectionDB.reflections.forEach((ref) => {
        this.reflections[ref.cubemapHash] = new TextureQueue()

        let reductionCube = reduction.reduction
        if (reductionCube === iReduction.x1) {
          reductionCube = iReduction.original
        }
        // reductionCube = iReduction.original
        const form = ref.atlasFormats.find(
          (e) => e.reduction === iReduction[reductionCube],
        )

        if (form !== undefined) {
          SceneLoaderUtilities.GetTextureFromAtlas(
            `${this.root}/${ref.filePath}/${form?.fileName}`,
            form,
            CubemapOrder.reflections,
          ).then((t) => {
            const tmp = this.reflections[ref.cubemapHash]

            tmp.texture = t
            tmp.texture.needsUpdate = true
            tmp.isLoaded = true
            this.HandleProgress()
            index++
            if (index == json.reflectionDB.reflections.length) {
              resolve(true)
            }
          })
        } else {
          console.log("NO FORMAT")
        }
      })
    })
  }

  getMobileOS = () => {
    const ua = navigator.userAgent
    if (/android/i.test(ua)) {
      return "Android"
    } else if (
      /iPad|iPhone|iPod/.test(ua) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    ) {
      return "iOS"
    }
    return "Other"
  }

  async LoadTextures(
    json: iMeshJson,
    reduction: iQualityReduction,
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      //load textures
      let index = 0
      if (json.textureDB.stexture2D.length === 0) {
        resolve(true)
        return
      }
      json.textureDB.stexture2D.forEach(async (texture) => {
        this.textures[texture.hash] = new TextureQueue()
        let [format] = texture.formats
        let t = {} as Texture

        if (texture.type === "NormalMap") {
          const form = texture.formats.find(
            (t) =>
              (t.width === reduction.resolution ||
                t.reduction === iReduction[reduction.reduction]) &&
              t.extension === "png",
          )
          if (form) {
            format = form
          }
          t = await Loaders.LoadTexture(
            `${this.root}/${texture.filePath}/${format.fileName}`,
          )
        } else {
          const form = texture.formats.find(
            (e) =>
              (e.width <= reduction.resolution ||
                e.height <= reduction.resolution ||
                e.reduction === iReduction[reduction.reduction]) &&
              e.extension === "basis",
          )
          if (form) {
            format = form
            t = await this.loaders.LoadBasisTexture(
              `${this.root}/${texture.filePath}/${format.fileName}`,
            )
          }
          // }
        }
        if (t) {
          t.wrapS = RepeatWrapping
          t.wrapT = RepeatWrapping
          t.anisotropy = 16
          t.minFilter = NearestMipmapLinearFilter
          t.magFilter = NearestFilter
          const tmp = this.textures[texture.hash]
          tmp.isLoaded = true
          tmp.texture = t
          tmp.texture.needsUpdate = true

          if (tmp.queue.length > 0) {
            tmp.queue.forEach((mat) => {
              tmp.texture?.repeat.set(mat.scale?.x || 1, mat.scale?.y || 1)
              tmp.texture?.offset.set(mat.offset?.x || 0, mat.offset?.y || 0)
              mat.material.setValues({ [mat.slot]: tmp.texture })
              mat.material.dispose()
            })
            tmp.queue = []
          }
        }
        index++
        this.HandleProgress()
        if (index == json.textureDB.stexture2D.length) {
          resolve(true)
        }
      })
    })
  }

  async LoadLightMaps(
    json: iMeshJson,
    reduction: iQualityReduction,
  ): Promise<boolean> {
    //load lightmaps
    return new Promise((resolve, reject) => {
      let count = 0
      if (json.lightmapDB.lightmaps.length === 0) {
        resolve(true)
        return
      }

      json.lightmapDB.lightmaps.forEach((lightMaps, index) => {
        this.lightMaps[index] = new TextureQueue()
        let [format] = lightMaps.formats
        const form = lightMaps.formats.find(
          (t) =>
            (t.width === reduction.resolution ||
              t.reduction === iReduction[reduction.reduction]) &&
            t.extension === "png",
        )
        if (form) {
          format = form
        }

        Loaders.LoadRGBMTexture(
          `${this.root}/${lightMaps.filePath}/${format.fileName}`,
        ).then((t) => {
          // t.encoding = RGBM7Encoding

          const tmp = this.lightMaps[index]
          t.needsUpdate = true
          tmp.isLoaded = true
          tmp.texture = t

          if (tmp.queue.length > 0) {
            tmp.queue.forEach((mat) => {
              const m = mat.material as MeshPhysicalMaterial
              m.setValues({
                lightMap: tmp.texture,
              })
              m.dispose()
            })
            tmp.queue = []
          }
          this.HandleProgress()
        })
        count++
        if (count == json.lightmapDB.lightmaps.length) {
          resolve(true)
        }
      })
    })
  }

  //#endregion

  HandleProgress() {
    this.progress++
    this.OnProgress && this.OnProgress(this.progress / this.objectCount)
  }

  //#region loaders

  Load(): Promise<void> {
    return new Promise((resolve, reject) => {
      const { nodes } = this.json
      const rnd = Math.floor(Math.random() * Math.floor(100000))
      get<ArrayBuffer>({
        link: `${this.root}/meshes/${this.json.meshGUID}/${this.json.meshGUID}.bin.gz?${rnd}`,
        responseType: "arraybuffer",
      }).then((data) => {
        gunzip(new Uint8Array(data), (err, res) => {
          if (err) {
            reject(err.message)
            return
          }
          Promise.all(
            nodes.map((go, index) => this.LoadObject(go, res.buffer, index)),
          ).then(() => {
            data = new ArrayBuffer(0)
            resolve()
          })
        })
      })
    })
  }

  LoadObject(go: iMeshData, data: ArrayBuffer, index: number) {
    return new Promise((resolve) => {
      let object: Mesh = new Mesh()

      if (go.meshrenderer.length > 0) {
        const [renderer] = go.meshrenderer
        LoadMesh(data, go.meshBufferOffset, go.meshBufferLength, renderer).then(
          (geometry) => {
            if (renderer) {
              this.meshes[index] = new MeshQueue()

              const meshData = this.meshes[index]
              meshData.meshData = go
              this.meshes[index].isLoaded = true
              const materials = renderer.materialGuids.map((guid) => {
                return SceneLoaderUtilities.HandleMaterial(
                  guid,
                  renderer.lightmapindex,
                  go,
                  this.json,
                  this.textures,
                  this.reflections,
                  this.lightMaps,
                  renderer.reflections,
                  this.group,
                )
              })
              object = new Mesh(geometry, materials)
              meshData.mesh = object

              object.name = go.name
              object.frustumCulled = false
              object.onAfterRender = () => {
                object.frustumCulled = true
                object.onAfterRender = () => {
                  /**/
                }
              }
              object.visible = true
              object.castShadow = false
              object.receiveShadow = false
              object.userData.objectData = go

              object.scale.set(
                go.trans.scale.x,
                go.trans.scale.y,
                go.trans.scale.z,
              )
              if (go.videoplayer.length > 0) {
                if (materials) {
                  const video = this.CreateVideo(go, object, materials)
                  // console.log(go.name)
                  // console.log(go)

                  video.setLoop(go.videoplayer[0].isLooping)
                  video.init()
                }
              }

              if (go.boxcollider.length > 0 && go.infopoints.length === 0) {
                if (go.boxcollider[0].enabled) {
                  const geometry = this.CreateBoxCollider(go)

                  const material = new MeshBasicMaterial({ color: 0x00ff00 })
                  material.wireframe = true
                  const cube = new Mesh(geometry, material)

                  cube.layers.set(3)
                  SceneLoaderUtilities.SetObjectTransform(
                    go,
                    cube,
                    this.resetPosition,
                  )
                  cube.renderOrder = 0
                  cube.name = "-collider-" + go.name

                  if (go.tag === "VideoPlayer") {
                    cube.userData.tag = "ArtPiece"
                    cube.userData.typetag = "Video"
                  }
                  cube.renderOrder = 0
                  this.group.add(cube)
                }
              }

              if (go.meshcollider.length > 0) {
                if (go.meshcollider[0].enabled) {
                  const geometry = object.geometry.clone()
                  const material = new MeshBasicMaterial({ color: 0x00ff00 })
                  material.wireframe = true
                  const cube = new Mesh(geometry, material)

                  cube.layers.set(3)
                  SceneLoaderUtilities.SetObjectTransform(
                    go,
                    cube,
                    this.resetPosition,
                  )
                  cube.renderOrder = 0
                  cube.name = "-collider-" + go.name

                  this.group.add(cube)
                }
              }

              if(go.infopoints.length > 0){
                let infoCollider: Object3D = new Mesh()
                const material = new MeshBasicMaterial({ color: 0xffff00 })
                material.wireframe = true

                let existingObject = this.group.getObjectByName("-collider-" + go.name);

                if(!existingObject){
                  let boxGeometry : BufferGeometry | undefined = undefined
                  if(go.boxcollider.length > 0){
                    boxGeometry = this.CreateBoxCollider(go)
                  }
                  else{
                    boxGeometry = geometry.clone()
                  }
                  existingObject = new Mesh(boxGeometry, material)
                  this.group.add(infoCollider)
                  console.log("created new collider")
                }
                
                
                infoCollider = existingObject as Object3D
                infoCollider.layers.set(3)
                SceneLoaderUtilities.SetObjectTransform(
                  go,
                  infoCollider,
                  this.resetPosition,
                )
                infoCollider.renderOrder = 0
                infoCollider.name = "-collider-" + go.name
                infoCollider.userData.tag = "InfoPoint"
                infoCollider.userData.typetag = "InfoPoint"
                infoCollider.userData.infoTarget = object
                infoCollider.userData.info = go.infopoints[0]
              }
              
              if(go.eventobjects !== undefined){
                if(go.eventobjects.length > 0){
                  const existingObject = this.group.getObjectByName("-collider-" + go.name);
                  if(existingObject){
                    existingObject.userData.event = go.eventobjects[0]
                    object.userData.event = go.eventobjects[0]
                  }
                }
              }

              this.group.add(object as Object3D)
              SceneLoaderUtilities.SetObjectTransform(
                go,
                object,
                this.resetPosition,
              )
              // object.visible = false
              // object.renderOrder = 1
              object.layers.set(0)
              this.group.add(object)
              this.HandleProgress()
              resolve(true)
            }
          },
        )
      } else {
        if (go.text.length > 0) {
          this.CreateTextObjectTroika(go.text[0], this.group)
        } else if (go.meshcollider.length > 0) {
          LoadMesh(data, go.meshBufferOffset, go.meshBufferLength).then(
            (geometry) => {
              const materials = new MeshBasicMaterial({ color: 0xff0000 })
              materials.wireframe = true
              const collider = new Mesh(geometry, materials)
              collider.userData.tag = go.tag
              if (go.tag === "Walkable") {
                collider.layers.set(1)
              } else {
                collider.layers.set(3)
              }
              collider.renderOrder = 0
              this.group.add(collider)
              SceneLoaderUtilities.SetObjectTransform(
                go,
                collider,
                this.resetPosition,
              )
            },
          )
        } else if (go.boxcollider.length > 0) {
          if (go.boxcollider[0].enabled) {
            const geometry = this.CreateBoxCollider(go)
            const material = new MeshBasicMaterial({ color: 0x00ff00 })
            material.wireframe = true
            const cube = new Mesh(geometry, material)
            cube.layers.set(3)
            cube.name = "-collider-" + go.name
            if (go.tag === "VideoPlayer") {
              cube.userData.tag = "ArtPiece"
              cube.userData.typetag = "Video"
              if(go.eventobjects){
                if(go.eventobjects.length > 0){
                  cube.userData.event = go.eventobjects[0]
                }
              }
              cube.layers.set(3)
            }
            SceneLoaderUtilities.SetObjectTransform(
              go,
              cube,
              this.resetPosition,
              false,
            )
            cube.renderOrder = 0
            this.group.add(cube)
          }
        } else {
          const emptyObject = new Object3D()
          emptyObject.name = go.name
          emptyObject.renderOrder = 0
          this.group.add(emptyObject)
          SceneLoaderUtilities.SetObjectTransform(
            go,
            emptyObject,
            this.resetPosition,
            true,
          )
        }
        resolve(true)
        this.HandleProgress()
      }
    })
  }

  CreateBoxCollider(mesh: iMeshData): BoxGeometry {
    const geometry = new BoxGeometry(
      mesh.boxcollider[0].size.x,
      mesh.boxcollider[0].size.y,
      mesh.boxcollider[0].size.z,
    )
    geometry.applyMatrix4(
      new Matrix4().makeTranslation(
        mesh.boxcollider[0].center.x,
        mesh.boxcollider[0].center.y,
        mesh.boxcollider[0].center.z,
      ),
    )
    return geometry
  }

  CreateVideo(
    mesh: iMeshData,
    object: Mesh,
    materials: Material[],
  ): VideoPlayer {
    const video = new VideoPlayer(
      mesh.videoplayer[0].url,
      mesh.videoplayer[0].url,
      mesh.videoplayer[0].url,
      object,
      materials[0] as MeshPhysicalMaterial,
      this.group,
    )
    video.position.set(
      mesh.trans.position.x,
      mesh.trans.position.y,
      mesh.trans.position.z,
    )
    return video
  }

  UpdateMeshes() {
    this.meshes.forEach(async (mesh) => {
      setTimeout(async () => {
        if (mesh.meshData != null) {
          const [renderer] = mesh.meshData.meshrenderer

          const materials = renderer.materialGuids.map((guid) => {
            if (mesh.meshData) {
              return SceneLoaderUtilities.HandleMaterial(
                guid,
                renderer.lightmapindex,
                mesh.meshData,
                this.json,
                this.textures,
                this.reflections,
                this.lightMaps,
                renderer.reflections,
              )
            }
          }) as (Material | Material)[]

          if (mesh.mesh) {
            mesh.mesh.material = []

            mesh.mesh.material = materials || new Material()
            materials.forEach((element) => {
              element.needsUpdate = true
            })
          }
        }
        this.HandleProgress()
      })
    })
  }

  //#endregion
  ReorderObjects() {
    this.json.nodes.forEach((node) => {
      let obj: any
      this.group.traverse((element) => {
        if (element.userData.objectId === node.objectID) {
          obj = element
        }
      })

      if (obj) {
        if (obj.userData.objectData) {
          const parentID = node.trans.ParentID
          obj.position.set(
            obj.userData.objectData.trans.position.x,
            obj.userData.objectData.trans.position.y,
            obj.userData.objectData.trans.position.z,
          )

          obj.receiveShadow = false

          let found: any
          this.group.traverse((element) => {
            if (element.userData.objectId === parentID) {
              found = element
            }
          })

          if (found) {
            found.receiveShadow = true
            found.castShadow = true
            found.needsUpdate = true
            found.attach(obj)
            obj.userData.parent = found?.id
          }
        }
      }
    })
  }

  CreateTextObjectTroika(text: iTextObject, group: Group, debug = false) {
    const tobject = new Text()
    const text64 = text.text

    const converted = atob(text64)
    iconv.skipDecodeWarning = true
    const utfText = iconv.decode(converted, "utf-16le")
    let yOffset = 0
    let xOffset = 0
    let newText = utfText.replace(/\r?\n|\r|\t/g, String.fromCharCode(10))

    if (text.fontStyle === 16) {
      newText = newText.toUpperCase()
    }
    tobject.text = newText

    if (debug) {
      //create box mesh
      const geometry = new BoxGeometry(
        text.rectTransform.sizeDelta.x,
        text.rectTransform.sizeDelta.y,
        0.2,
      )
      console.log(text)

      const color = new Color(text.color.r, text.color.g, text.color.b)

      const material = new MeshBasicMaterial({ color: color, wireframe: true })
      const cube = new Mesh(geometry, material)
      cube.scale.set(
        text.rectTransform.transform.scale.x,
        text.rectTransform.transform.scale.y,
        text.rectTransform.transform.scale.z,
      )

      //set cube rotation
      cube.rotation.setFromQuaternion(
        new Quaternion(
          text.rectTransform.transform.rotation.x,
          text.rectTransform.transform.rotation.y,
          text.rectTransform.transform.rotation.z,
          text.rectTransform.transform.rotation.w,
        ),
      )

      //set cube position
      cube.position.set(
        text.rectTransform.transform.position.x,
        text.rectTransform.transform.position.y,
        text.rectTransform.transform.position.z,
      )

      group.add(cube)
      console.log(eTextAlignment[text.alignment], text.fontName, newText)
    }

    xOffset =
      text.rectTransform.sizeDelta.x *
      text.rectTransform.pivot.x *
      text.rectTransform.transform.scale.x *
      -1
    yOffset =
      text.rectTransform.sizeDelta.y *
      text.rectTransform.pivot.y *
      text.rectTransform.transform.scale.y

    switch (eTextAlignment[text.alignment]) {
      case "TopJustified":
        tobject.textAlign = "justify"
        tobject.anchorY = "top"
        break
      case "Right":
        tobject.textAlign = "right"
        tobject.anchorY = "middle"
        yOffset = 0
        break
      case "Left":
        tobject.textAlign = "left"
        tobject.anchorY = "middle"
        yOffset = 0
        break
      case "Top":
        tobject.textAlign = "center"
        tobject.anchorY = "top"
        break
      case "TopLeft":
        tobject.textAlign = "left"
        tobject.anchorX = "left"
        tobject.anchorY = "top"
        break
      case "Center":
        tobject.textAlign = "center"
        tobject.anchorX = "center"
        tobject.anchorY = "middle"
        yOffset = 0
        xOffset = 0
        break
    }

    const offset = new Vector3(xOffset, yOffset, 0)

    tobject.font =
      window.location.origin + "/fonts/" + text.fontName.replace(" SDF", ".ttf")

    tobject.scale.set(
      text.rectTransform.transform.scale.x,
      text.rectTransform.transform.scale.y,
      text.rectTransform.transform.scale.z,
    )
    tobject.letterSpacing =
      text.characterSpacing * text.rectTransform.transform.scale.x
    tobject.fontSize = text.fontSize * 0.9
    const spacing = text.lineSpacing == 0 ? 1 : text.lineSpacing
    tobject.lineHeight = 0 // spacing * text.rectTransform.transform.scale.x
    tobject.color = new Color(
      text.color.r,
      text.color.g,
      text.color.b,
    ).convertSRGBToLinear()

    tobject.fillOpacity = text.color.a

    tobject.maxWidth = text.rectTransform.sizeDelta.x
    tobject.maxHeight = text.rectTransform.sizeDelta.y

    // if (text.fontStyle === 1) {
    //   console.log("bold", newText)

    //   tobject.strokeWidth = "-1000%"
    //   tobject.strokeColor = tobject.color
    //   tobject.strokeOpacity = text.color.a
    // }

    tobject.font = fontURLS[text.fontName]
    tobject.rotation.setFromQuaternion(
      new Quaternion(
        text.rectTransform.transform.rotation.x,
        text.rectTransform.transform.rotation.y,
        text.rectTransform.transform.rotation.z,
        text.rectTransform.transform.rotation.w,
      ),
    )

    offset.applyQuaternion(tobject.quaternion)

    tobject.position
      .set(
        text.rectTransform.transform.position.x,
        text.rectTransform.transform.position.y,
        text.rectTransform.transform.position.z,
      )
      .add(offset)

    tobject.sync()
    group.add(tobject)
  }
}
