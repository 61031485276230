import React from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import "./App.css"
import Artifact from "./Pages/Artifact/Artifact"
import ArtPiece from "./Pages/ArtPiece/ArtPiece"
import CocaCola from "./Pages/CocaCola/CocaCola"
import Exhibition from "./Pages/Exhibition/Exhibition"
import Reveal from "./Pages/Reveal/Reveal"

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact={true} path="/exhibition/:url">
          <Exhibition />
        </Route>
        <Route exact={true} path="/reveal">
          <Reveal />
        </Route>
        <Route exact={true} path="/artifact/:url">
          <Artifact />
        </Route>
        <Route exact={true} path="/art-piece/:url">
          <ArtPiece />
        </Route>
        <Route exact={true} path="/coca-cola">
          <CocaCola />
        </Route>
      </Switch>
    </Router>
  )
}

export default App
