import {
  AmbientLight,
  Color,
  DirectionalLight,
  HemisphereLight,
  Light,
  Mesh,
  PlaneGeometry,
  PointLight,
  Scene,
  ShadowMaterial,
} from "three"

interface iEndlessEnvironmentResult {
  light: Light
}

export function EndlessEnvironment(scene: Scene): iEndlessEnvironmentResult {
  scene.background = new Color(0xa0a0a0)

  const geometry = new PlaneGeometry(10, 10, 100, 100)
  const shadowMaterial = new ShadowMaterial()
  shadowMaterial.opacity = 0.5
  const mesh = new Mesh(geometry, shadowMaterial)
  mesh.rotation.x = -Math.PI / 2
  mesh.receiveShadow = true
  scene.add(mesh)

  const light = new DirectionalLight(0xffffff, 1)
  light.position.set(0, 1, -10)
  light.castShadow = true

  light.shadow.camera.near = 0.1
  light.shadow.camera.far = 5
  light.shadow.camera.right = 2
  light.shadow.camera.left = -2
  light.shadow.camera.top = 2
  light.shadow.camera.bottom = -2
  light.shadow.mapSize.width = 512
  light.shadow.mapSize.height = 512
  scene.add(light)

  // const ambientLight = new AmbientLight(0xffffff, 0.05)
  // scene.add(ambientLight)

  const hemiLight = new HemisphereLight(0xffffff, 0xffffff, 0.05)
  // hemiLight.color.setHSL(0.6, 1, 0.6)
  // hemiLight.groundColor.setHSL(0.095, 1, 0.75)
  hemiLight.position.set(0, 0, 5)
  scene.add(hemiLight)

  return { light }
}
