import React, { useEffect, useRef, useState } from "react"
import * as THREE from "three"
import { OrbitControls } from "../../Controls/OrbitControl/OrbitControl"
import { useScene } from "../../hooks/useScene"
import { EndlessEnvironment } from "../../Environment/EndlessEnvironment"
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader"
import { TextureLoader } from "three"
import { PointerRaycast } from "../../Controls/FirstPersonControls/PointerRaycat"
import { ReactComponent as FindTheSecretCode } from "../../cocacola/find_the_secret_codeAsset 3.svg"
import { ReactComponent as Lock } from "../../cocacola/icon-lockAsset 3.svg"
import { ReactComponent as Unlock } from "../../cocacola/mission_unlockedAsset 2.svg"
import { ReactComponent as Leader } from "../../cocacola/leader_of_the_packAsset 4.svg"
// console.log(findTheSecretCode)

const letters = ["L", "C", "2", "1"]
const objectLetters = ["C", "1", "2", "L"]

const CocaCola = () => {
  const _displayedCode = useRef(new Array(4))
  const [displayedCode, setDisplayedCode] = useState(new Array(4))
  const [isLoading, setLoading] = useState(true)
  const [showOverlay, setShowOverlay] = useState(false)
  const [isOneCodeFound, setIsOneCodeFound] = useState(false)
  const [showEndScreen, setShowEndScreen] = useState(false)
  const [showPasscode, setShowPasscode] = useState(false)

  const codeCount = useRef(0)

  const container = useRef<HTMLDivElement>(null)

  const { renderer, scene } = useScene({ container })

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
      setShowOverlay(true)
    }, 3000)

    const { light } = EndlessEnvironment(scene)

    //Create a plane that receives shadows (but does not cast them)
    const camera = new THREE.PerspectiveCamera(
      60,
      window.innerWidth / window.innerHeight,
      0.1,
      10000,
    )
    const pointerRaycaster = new PointerRaycast(
      camera,
      1000,
      scene,
      renderer.domElement,
    )
    light.intensity = 0.6

    camera.position.set(15, 15, 15)

    const group = new THREE.Group()
    group.scale.set(1, 1, 1)
    group.position.set(0, -7, 0)
    scene.add(group)

    const controls = new OrbitControls(camera, renderer.domElement)
    controls.enablePan = true
    controls.enableDamping = true // an animation loop is required when either damping or auto-rotation are enabled
    controls.dampingFactor = 0.1

    //start loading scene geometry

    const texture = new TextureLoader().load(
      "textures/coca-cola/numbers_background.jpg",
    )

    texture.wrapS = THREE.RepeatWrapping
    texture.wrapT = THREE.RepeatWrapping

    const loader = new FBXLoader()

    loader.load("models/flasa.FBX", (obj: any) => {
      const matBottle = new THREE.MeshStandardMaterial({
        map: texture,
        color: 0xc90603,
        roughness: 0.8,
      })
      matBottle.color.convertGammaToLinear()
      const matArrow = new THREE.MeshStandardMaterial({
        map: texture,
        color: 0x67cfc8,
        roughness: 0.2,
      })
      matArrow.color.convertGammaToLinear()

      const matLetter = new THREE.MeshLambertMaterial({
        opacity: 0,
        transparent: true,
      })

      let count = 0

      obj.children.forEach((child: THREE.Mesh, index: number) => {
        if (index === 3) {
          child.material = matBottle
        } else if (index === 4) {
          child.material = matArrow
        } else {
          child.material = matLetter
          child.userData = {
            tag: "target",
            value: count,
            isTagged: false,
          }
          count++
        }
      })
      controls.target = obj.position.clone()

      group.add(obj)
    }, (xr: any)=>{
      console.log(xr)
    })

    const onPointerUp = (event: TouchEvent) => {
      const position = new THREE.Vector2(
        (event.changedTouches[0].clientX / window.innerWidth) * 2 - 1,
        -(event.changedTouches[0].clientY / window.innerHeight) * 2 + 1,
      )

      traceInteractable(position)
    }

    renderer.domElement.addEventListener("touchend", onPointerUp, false)

    function traceInteractable(position: THREE.Vector2) {
      const interactable = pointerRaycaster.traceFromCameraByTag(
        position,
        "target", //add custom tag
        100,
        0,
      )
      if (interactable !== undefined) {
        //invoke interact event
        const { value, isTagged } = interactable.object.userData || {}

        if (!isTagged) {
          const realIndex = letters.indexOf(objectLetters[value])

          const tmp = [..._displayedCode.current]
          tmp[realIndex] = objectLetters[value]
          _displayedCode.current = tmp

          setDisplayedCode(tmp)
          setIsOneCodeFound(true)

          codeCount.current++
          if (codeCount.current === 4) {
            setShowOverlay(false)
            setShowEndScreen(true)
            setTimeout(() => {
              setShowPasscode(true)
            }, 2000)
          }
          interactable.object.userData.isTagged = true
        }
      }
    }

    window.addEventListener("resize", onWindowResize, false)

    function onWindowResize() {
      console.log("onresize")

      camera.aspect = window.innerWidth / window.innerHeight
      camera.updateProjectionMatrix()

      renderer.setSize(window.innerWidth, window.innerHeight)
    }

    controls.update()

    function animate() {
      requestAnimationFrame(animate)
      controls.update()
      renderer.render(scene as THREE.Scene, camera)
    }
    animate()
  }, [])

  return (
    <div ref={container} className="App">
      {isLoading && (
        <div className="absolute-container loader-background">
          <div>
            <FindTheSecretCode />
          </div>
        </div>
      )}
      {showOverlay && (
        <div className="absolute-container overlay-padding">
          <div className="overlay">
            {isOneCodeFound ? (
              <div className="code">
                <Lock />
                <div className="code-list">
                  {displayedCode.map((code, index) => (
                    <div className="single-code" key={index}>
                      <p>{code}</p>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="find">
                <Lock />
                <div className="text">
                  <p>CODE SEARCHING...</p>
                  <span>{"ROTATE & ZOOM"}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {showEndScreen && (
        <div
          style={{
            backgroundColor: showPasscode ? "black" : "",
            backgroundImage: showPasscode
              ? "url('/cocacola/background.png')"
              : "",
            padding: showPasscode ? "30px" : "20px",
            paddingBottom: showPasscode ? "50px" : "20px",
          }}
          className="absolute-container loader-background"
        >
          {showPasscode ? (
            <div className="leader">
              <div>
                <Leader />
              </div>
              <div className="leader-text">
                <p>
                  Full mission toolkit and examples to inspire are waiting for
                  you in the virtual gallery after the conference.
                </p>
              </div>
              <div className="passcode">
                <span>Save your passcode:</span>
                <p
                  style={{
                    backgroundImage: "url(/cocacola/border.png)",
                    padding: "5px",
                  }}
                >
                  zerowastemission21
                </p>
              </div>
            </div>
          ) : (
            <div>
              <Unlock />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default CocaCola
