import React, { FunctionComponent, useRef, useState } from "react"
import { isMobile } from "react-device-detect"
import "./Menu.css"

interface iSrc {
  object: any
  audioSrc?: string
  title?: string
  subtitle?: string
}

const MobileMenu: FunctionComponent<iSrc> = ({
  object,
  audioSrc,
  title,
  subtitle,
}) => {
  const isFullscreen = useRef(false)
  const [displayInfo, setDisplayInfo] = useState(false)

  return (
    <>
      <div className="control_root">
        {isMobile && displayInfo && (
          <>
            <div className="topTitleHolder">{title}</div>
            <div className="bottomTitleHolder">{subtitle}</div>
          </>
        )}
        <div className="control_wrap">
          {isMobile && (
            <>
              <div
                onClick={() => setDisplayInfo(!displayInfo)}
                className="control_info"
              >
                <span>Info</span>
              </div>
              {audioSrc && (
                <audio autoPlay controls>
                  <source src={audioSrc} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              )}
            </>
          )}

          <button
            style={{ marginLeft: audioSrc && isMobile ? "0" : "auto" }}
            className="fullscreen-button"
            onClick={(e) => {
              e.stopPropagation()
              isFullscreen.current ? closeFullscreen() : openFullscreen(object)
              isFullscreen.current = !isFullscreen.current
            }}
          >
            <img src={"/icons/fullscreen.png"}></img>
          </button>
        </div>
      </div>
    </>
  )
}

export default MobileMenu

function openFullscreen(elem: any) {
  if (elem.requestFullscreen) {
    elem.requestFullscreen()
  } else if (elem.mozRequestFullScreen) {
    /* Firefox */
    elem.mozRequestFullScreen()
  } else if (elem.webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    elem.webkitRequestFullscreen()
  } else if (elem.msRequestFullscreen) {
    /* IE/Edge */
    elem.msRequestFullscreen()
  }
}

function closeFullscreen() {
  const doc = document as any
  if (doc.exitFullscreen) {
    doc.exitFullscreen()
  } else if (doc.webkitExitFullscreen) {
    /* Safari */
    doc.webkitExitFullscreen()
  } else if (doc.msExitFullscreen) {
    /* IE11 */
    doc.msExitFullscreen()
  }
}
