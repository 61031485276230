import React, { FunctionComponent } from "react"
import { iExhibition } from "../../definitions"
import { eImageVersions } from "../../definitions/Image"
import { useImageUrl } from "../../hooks/useImageUrl"
import "./ExhibitionLoader.css"

interface iProps {
  onClick: any
}

const RevealLoader: FunctionComponent<iProps> = ({
  onClick,
}) => {
  const imageUrl = useImageUrl(
    eImageVersions.LWRegular,
  )


  return (
    <div className="exhibition-loader">
      <div
        className="background"
        style={{
          backgroundImage: `url(${imageUrl})`,
          filter: `blur(${blur}px)`,
        }}
      ></div>
      <div className="loader-wrapper">
        <div className="exhibition-logo">
          <img src="/icons/all.art_logo_dafa.png" alt="" />
        </div>
        <button type="submit" className="reveal-btn" onClick={() => onClick?.()}>ENTER</button>
        <div className="revealHolder">
        </div>
      </div>
    </div>
  )
}

export default RevealLoader
