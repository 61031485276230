import React, { FunctionComponent, useEffect, useRef, useState } from "react"
import * as THREE from "three"
import { FirstPersonControls } from "../../Controls/FirstPersonControls/FirstPersonControls"
import {
  iExhibition,
} from "../../definitions"
import { Group, LinearToneMapping, Mesh, sRGBEncoding } from "three"
import Stats from "../../Helpers/Stats/Stats"
import SceneLoader from "../../Loaders/SceneLoader/SceneLoader"
import { ReactComponent as Close } from "../../Components/Icons/close.svg"
import { useHistory, useRouteMatch } from "react-router-dom"
import { getMesh } from "../../Helpers/requests"
import { parseQuery } from "../../Helpers/functions"
import { unstable_batchedUpdates } from "react-dom"
import { useScene } from "../../hooks/useScene"
import { SceneLoaderUtilities } from "../../Loaders/SceneLoader/SceneLoaderUtilities"
import { isMobile } from "react-device-detect"
import Menu from "../../Components/Overlay/Menu"
import RevealLoader from "../../Components/Loaders/RevealLoader"
import { VideoPlayer } from "../../Components/VideoPlayer/VideoPlayer"
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader"
import { Loaders } from "../../Loaders/SceneLoader/Loaders"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import ExhibitionLoader from "../../Components/Loaders/ExhibitionLoader"


const Reveal = () => {
  const [isLoading, setLoading] = useState(true)
  const [isAddLoading, setAddLoading] = useState(false)
  const [hasDoubleTapped, setHasDoubleTapped] = useState(false)
  const [isVideoHovered, setVideoHovered] = useState(false)
  const [videoPlayer, setVideoPlayer] = useState<VideoPlayer>()
  const [isPlaying, setPlaying] = useState(false)

  const [showIframe, setShowIframe] = useState(false)
  const [iframeSource, setIframe] = useState("")
  const [progress, setProgress] = useState(0)
  const [exhibition, setExhibition] = useState<iExhibition>()
  const [audioGuideURL, setAudioGuide] = useState("")
  const [videoSrc, setVideoSrc] = useState("")
  const [videoMesh, setVideoMesh] = useState<THREE.Mesh>()
  const [hasEntered, setHasEntered] = useState(false)

  const container = useRef<HTMLDivElement>(null)
  const match = useRouteMatch<{ url: string }>()
  const history = useHistory()
  const outlineThickness = 1.015
  const playerHeight = 1.65
  let previousSceneHDR: THREE.CubeTexture

  //camera
  const fieldOfView = 60
  const near = 0.1
  const far = 10000

  //tags
  const artPieceTag = "ArtPiece"
  const outlineTag = "outline"
  const videoTag = "Video"
  const portalTag = "Portal"
  const infoTag = "InfoPoint"
  let cubeCamera: THREE.CubeCamera;
  let cubeRenderTarget: THREE.WebGLCubeRenderTarget;
  let query: any;


  let video: HTMLVideoElement 
  let main_scene_group: THREE.Group

  //roots
  const vrAllArtRoot = "https://content.vrallart.com"

  //resolutions
  const mobileResolution = 1024
  const pcResolution = 2048

  const { renderer, scene } = useScene({ container })

  let outline: THREE.Object3D

  useEffect(() => {
    const container = document.getElementById("stats")
    window.addEventListener("resize", onWindowResize)

    const stats = Stats()
    container?.appendChild(stats.dom)
    scene.background = new THREE.Color(0, 0, 0)
    const camera = new THREE.PerspectiveCamera(
      fieldOfView,
      window.innerWidth / window.innerHeight,
      near,
      far,
    )
    cubeRenderTarget = new THREE.WebGLCubeRenderTarget(1024, { generateMipmaps: true, minFilter: THREE.LinearMipmapLinearFilter });
    cubeCamera = new THREE.CubeCamera(near, far, cubeRenderTarget);
    cubeCamera.layers.set(0)
    cubeCamera.layers.disable(5)

    scene.add(cubeCamera)
    cubeCamera.position.set(0, 0, 0)
    cubeCamera.update(renderer, scene)

    camera.layers.enable(0)
    camera.layers.disable(1)
    camera.layers.disable(2)
    camera.layers.disable(3)
    camera.layers.enable(5)

    const scenes: Group[] = []

    if (!isMobile) {
      renderer.setPixelRatio(window.devicePixelRatio)
    } else {
      renderer.setPixelRatio(window.devicePixelRatio / 2)
    }

    renderer.outputEncoding = sRGBEncoding
    renderer.toneMapping = LinearToneMapping
    renderer.toneMappingExposure = 1
    renderer.setSize(window.innerWidth, window.innerHeight - 10)

    main_scene_group = new THREE.Group()
    scene.add(main_scene_group)
    main_scene_group.scale.set(0.01,0.01,0.01)

    scenes.push(main_scene_group)

    

    query = parseQuery(history.location.search)
    setVideoSrc(query.video)

    const controls = new FirstPersonControls(
      camera,
      renderer.domElement,
      playerHeight,
      scene,
    )

    loadSpace(query.video, query.video, main_scene_group)
    async function LoadLightmap(url: string){
      const t = Loaders.LoadRGBMTexture(
        url,
      )      
      return t
    }

    async function LoadTexture(url: string){
      const t = await Loaders.LoadTexture(
        url,
      )
      return t
    }



    async function loadSpace(
      url: string,
      videoUrl: string,
      group: THREE.Group,
      fromscene = ""
    ) {

      let wallNorm: THREE.Texture
      await LoadTexture("textures/refik/walls/vkmedfr_2K_Normal.jpg").then((t)=>{
        t.wrapS = THREE.RepeatWrapping
        t.wrapT = THREE.RepeatWrapping
        // t.repeat = new THREE.Vector2(10,10)
        t.needsUpdate = true
        wallNorm = t
       })
      let wallAlb: THREE.Texture
      await LoadTexture("textures/refik/walls/vkmedfr_2K_AO.jpg").then((t)=>{
        t.wrapS = THREE.RepeatWrapping
        t.wrapT = THREE.RepeatWrapping
        // t.repeat = new THREE.Vector2(10,10)
        t.needsUpdate = true
        wallAlb = t
       })
      let floorNorm: THREE.Texture
      await LoadTexture("textures/refik/floor/vlkhdgn_2K_Normal.jpg").then((t)=>{
        t.wrapS = THREE.RepeatWrapping
        t.wrapT = THREE.RepeatWrapping
        // t.repeat = new THREE.Vector2(10,10)
        t.needsUpdate = true
        floorNorm = t
       })
      let floorAlb: THREE.Texture
      await LoadTexture("textures/refik/floor/vlkhdgn_2K_AO.jpg").then((t)=>{
        floorAlb = t
       })
      let lightmap: THREE.Texture
      await LoadLightmap("textures/refik/lightmapenzi_bite.png").then((t)=>{
        lightmap = t
        lightmap.flipY = true
        lightmap.needsUpdate = true
      })
      
      const loader = new FBXLoader()

      loader.load("models/room.fbx", (obj: any) => {
        setLoading(true)
        const matFloor = new THREE.MeshPhysicalMaterial({
          color: 0x000000,
          roughness: 0.1,
          metalness: 0.5,
          lightMapIntensity: 1
        })

        matFloor.setValues({
          map: floorAlb,
          normalMap: floorNorm,
          lightMap: lightmap
        })
        matFloor.needsUpdate = true

        if (cubeRenderTarget != undefined) {
          matFloor.envMap = cubeRenderTarget.texture
        }
        const matWalls = new THREE.MeshPhysicalMaterial({
          color: 0x3f3f3f,
          roughness: 0.8,
          lightMapIntensity: 3
        })

        matWalls.setValues({
          map: wallAlb,
          normalMap: wallNorm,
          lightMap: lightmap
        })
        matWalls.color.convertGammaToLinear()
        matWalls.needsUpdate = true
        main_scene_group.add(obj)
        

        obj.children.forEach((child: any, index: number) => {          
          if (child.name == "floor") {
            SceneLoaderUtilities.UpdateBoxProjectionMaterial(matFloor, new THREE.Vector3(12, 12, 8), new THREE.Vector3(0, 0, 0))
            child.material = matFloor;
            matFloor.metalness = 0
            matFloor.roughness = 0.1
            matFloor.clearcoat = 0
            matFloor.color = new THREE.Color("black")
            matFloor.needsUpdate = true


            const clone = child.clone()
            clone.userData.tag = "Walkable"
            clone.layers.set(1)
            main_scene_group.add(clone)
          }
          else if (child.name == "walls") {
            child.material = matWalls
            const clone = child.clone() as THREE.Mesh
            const material = new THREE.MeshBasicMaterial({ color: 0xffff00 })
            material.wireframe = true
            clone.layers.set(3)
            clone.material = material
            main_scene_group.add(clone)
          }
          else if (child.name == "video") {
            setVideoMesh(child)
            const material = new THREE.MeshPhysicalMaterial({
              color: 0xffffff,
              emissive: 0xffffff
            })
            const pl = new VideoPlayer("vd", query.video, "iii", child, material, group)
            pl.setLoop(true)
            setVideoPlayer(pl)
          }
          else if (child.name.includes("Light")) {
            const lightMat = new THREE.MeshPhysicalMaterial({
              color: 0xCED9FF,
              emissive: 0xCED9FF,
              emissiveIntensity: 1,
              flatShading: true
            })
            child.material = lightMat
          }
        })
        setLoading(false)
        controls.setPosition(new THREE.Vector3(1.5,0,0), new THREE.Quaternion().setFromEuler(new THREE.Euler(0, -Math.PI / 2, 0, "XYZ"),true), false)
      }, (xr: any) => {
        setProgress(xr.loaded / xr.total)
      })

    }
    
    function toggleInteractableObjects(scene: THREE.Group, active: boolean): void {
      scene.traverse((object: THREE.Object3D) => {

        object.userData.interactable = active;
      });
    }

    function controllerInteractEvent(event: any) {
      const interact = event.message
      let url = ""
      if (interact.externalUrl !== undefined && interact.externalUrl !== "") {
        url = interact.externalUrl
      } else if (
        interact.destination !== undefined &&
        interact.destination !== ""
      ) {
        url = interact.destination
      }
      else if (interact.info !== undefined) {
        const info = interact.info
        const eventData = {
          id: info.title,
          type: info.description,
        }

        window.parent.postMessage(eventData, "*")
        window.postMessage(eventData, "*")
        return;
      }
      else {
        url = interact.url
      }

      if (interact.event !== undefined) {
        if (interact.event.eventType !== "") {
          const eventData = {
            id: interact.event.id,
            type: interact.event.eventType,
          }

          window.parent.postMessage(eventData, "*")
          window.postMessage(eventData, "*")
          return;
        }
      }

      if (url === "" || url.includes("undefined") === true) {
        return
      }

      unstable_batchedUpdates(() => {
        window.open(url, "_blank")
      })
    }

    function clearGroup(group: THREE.Group) {
      while (group.children.length > 0) {
        group.remove(group.children[0])
      }
    }

    //#region create player controller
    function subscribeToControllerEvents(controls: any) {
      //controller events
      //add listener on controller interaction
      controls.addEventListener("interact", controllerInteractEvent)

      //add listener on controller hover enter for interactable objects
      controls.addEventListener("onInteractableHover", function (event: any) {
        if (isMobile) return
      })

      controls.addEventListener(
        "onInteractableHoverVideo",
        function (event: any) {
          setVideoHovered(true)
          if (event.message.userData.colTarget === videoPlayer) return
          setVideoPlayer(event.message.userData.colTarget)
          setPlaying(event.message.userData.colTarget?.isPlaying)
        },
      )

      controls.addEventListener(
        "onInteractableHoverEndVideo",
        function (event: any) {
          // setVideoPlayer(undefined)
          setVideoHovered(false)
        },
      )

      controls.addEventListener("onDoubleTapped", function (event: any) {
        setHasDoubleTapped(true)
      })

      //add listener on controller hover exit for interactable objects
      controls.addEventListener("onInteractableHoverEnd", function () {
        if (isMobile) return
        if (outline) {
          outline.visible = false
        }
        outline?.remove()
        outline = new THREE.Object3D()
      })
    }

    controls.init(
      main_scene_group.position.x,
      main_scene_group.position.y,
      main_scene_group.position.z,
    )

    function onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight
      camera.updateProjectionMatrix()
      renderer?.setSize(window.innerWidth, window.innerHeight)
    }

    function animate() {
      requestAnimationFrame(animate)
      controls.update()
      cubeCamera.update(renderer, scene);
      renderer?.render(scene as THREE.Scene, camera)
    }



    animate()
  }, [])

  const onInfoClose = () => {
    unstable_batchedUpdates(() => {
      setIframe("")
      setShowIframe(false)
    })
  }

  const onPlayClick = () => {
    if (videoPlayer) {
      setPlaying(!videoPlayer?.isPlaying)
      videoPlayer.toggleState()
    }
  }

  function playVideo() {
    videoPlayer?.play()
  }

  const onClick = () => {
    setHasEntered(true)
    playVideo()
  }

  return (
    <div ref={container} className="App">
      {/* <video id="video" loop muted crossOrigin="anonymous" playsInline style={{display: "none"}}>
        <source src={videoSrc}/>
		  </video> */}
      {showIframe && (
        <div>
          <div onClick={onInfoClose} className="close">
            <Close />
          </div>
          <iframe src={iframeSource} frameBorder="0"></iframe>
        </div>
      )}
      {(isLoading && hasEntered == false) && (
        <ExhibitionLoader progress={progress}/>
      )}
      {
        (!isLoading && hasEntered == false) && (
          <RevealLoader onClick={onClick} />
        )
      }
      {isAddLoading && (
        <div className="additionalLoader">
          <div className="additionalLoaderWrap">
            <h1>HIGH QUALITY LOADING</h1>
            <div className="innerLoaderHolder">
              <div
                className="innerLoader"
                style={{ width: progress * 100 + "%" }}
              ></div>
            </div>
          </div>
        </div>
      )}
      {isLoading === false && hasEntered ? (
        <>
          <div className="screenOverlay">
            {/* <img
              className="logo"
              src={window.location.origin + "/icons/all.art_logo_dafa.png"}
            /> */}
            {isAddLoading === false && (
              <>
                {!isMobile ? (
                  <div className="bottomPannel">
                    <div className="bottomKeys">
                      <img
                        src={window.location.origin + "/icons/vraa_buttons.png"}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    {hasDoubleTapped === false && (
                      <div className="bottomPannel">
                        <div className="additionalLoader">
                          <div className="additionalLoaderWrap">
                            <h1>DOUBLE TAP TO MOVE</h1>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
            {isVideoHovered && isMobile && (
              <div className="videoHover">
                <div className="videoHoverInner">
                  <button className="play-button" onClick={onPlayClick}>
                    {isPlaying ? (
                      <img
                        src={window.location.origin + "/icons/pause_new.png"}
                      />
                    ) : (
                      <img
                        src={window.location.origin + "/icons/play_new.png"}
                      />
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>
          <Menu
            audioSrc={audioGuideURL}
            title={exhibition?.title}
            subtitle={exhibition?.subtitle}
            object={container.current}
          />
        </>
      ): (<></>)}
    </div>
  )
}

export default Reveal
